import {MaskedInput, TForm} from '@thx/controls';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Form, FormInput, List, ListItem} from 'semantic-ui-react';
import {number, object} from 'yup';
import {ContactDropdown} from '~common/components/ContactDropdown';
import {ContactTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useCreatePartnerMutation} from '../PartnerCreateForm/createPartner';

const d = debug('tacs.web.accounts.components.partners.PartnerAddSidebarExistingContactList');

const partnerAddSidebarContactListValidation = object().shape({
	ownershipPercentage: number().required('The partner ownership percentage is required.'),
});

interface PartnerAddSidebarContactListValidationSchemaType {
	contactId: string;
	ownershipPercentage: string;
}

export function PartnerAddSidebarExistingContactList() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.createPartner>();

	const [createPartnerMutation, {loading: mutationLoading}] = useCreatePartnerMutation();

	const onCreatePartnerClick = (contactId: string, ownershipPercentage: string) => {
		createPartnerMutation({
			variables: {
				data: {
					contactId,
					accountInfoId,
					partner: {
						ownershipPercentage: parseInt(ownershipPercentage, 10),
					},
				},
			},
		})
			.then(() => push(routes.to.partners({accountInfoId})))
			.catch(throwError);
	};

	return (
		<List>
			<List.Header as="h3">Add Partner from Existing Contacts</List.Header>
			<TForm<PartnerAddSidebarContactListValidationSchemaType>
				initialValues={{}}
				onSubmit={vals => onCreatePartnerClick(vals.contactId, vals.ownershipPercentage)}
				validationSchema={partnerAddSidebarContactListValidation}
			>
				{tFormProps => {
					const {setFieldValue, handleSubmit, values, hasErrors, hasWarnings, renderWarnings, fieldError} = tFormProps;
					return (
						<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
							<List>
								<ListItem>
									<ContactDropdown
										accountInfoId={accountInfoId}
										missingContactType={ContactTypeEnum.Partner}
										onSelect={v => {
											setFieldValue('contactId', v?.id);
										}}
									/>
								</ListItem>
								{values.contactId && (
									<>
										<Divider />
										<List.Item>
											<FormInput label="Partner Ownership Percentage" required error={fieldError('partner.ownershipPercentage')}>
												<MaskedInput
													mask={{mask: '99[9]', autoUnmask: true, numericInput: true}}
													onChange={(val: string) => setFieldValue('ownershipPercentage', parseInt(val, 10))}
													value={values.ownershipPercentage || ''}
												/>
											</FormInput>
										</List.Item>
										<List.Item>{renderWarnings()}</List.Item>
										<List.Item>
											<Button disabled={mutationLoading || locked} floated="right" positive type="submit">
												Save
											</Button>
											<Button type="button" floated="right" onClick={() => setFieldValue('contactId', undefined)}>
												Cancel
											</Button>
										</List.Item>
										<Divider />
									</>
								)}
							</List>
						</Form>
					);
				}}
			</TForm>
		</List>
	);
}
