import {TForm} from '@thx/controls';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Form, Grid, GridColumn, GridRow, Loader} from 'semantic-ui-react';
import {mixed, object, string} from 'yup';
import {PreviewGlAccounts} from '~common/components/PreviewGlAccounts/PreviewGlAccounts';
import {getWarningMessage} from '~common/components/PreviewGlAccounts/PreviewGlAccountsModal';
import {CreateFinancialAccountInput, CurrencyCountryEnum, FinancialAccountTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {FinancialAccountForm} from '../CreateFinancialAccountForm';
import {useCreateFinancialAccountMutation} from './createFinancialAccount';
import {useGetFinancialAccountPreviewDataQuery} from './getFinancialAccountPreviewData';

const CreateFinancialAccountValidationSchema = object().shape({
	institutionId: string().required('Institution is required.'),
	accountNumber: string().required('Account Number is required.'),
	accountNickname: string().required('Account Nickname is required.'),
	accountCurrency: mixed().oneOf(Object.values(CurrencyCountryEnum)).required('Currency is required.'),
	type: mixed().oneOf(Object.values(FinancialAccountTypeEnum)).required('Account type is required.'),
});

interface CreateFinancialAccountModalProps {
	financialAccountName: string;
	financialAccountType: FinancialAccountTypeEnum;
	onSave: (val?: string) => void;
	onCancel: () => void;
}

export function CreateFinancialAccountModal({financialAccountName, financialAccountType, onCancel, onSave}: CreateFinancialAccountModalProps) {
	const {accountInfoId} = useParams<typeof routes.types.createFinancialAccount>();
	const throwError = useAsyncError();

	const [createFinancialAccount, {loading, error, data}] = useCreateFinancialAccountMutation();

	const {
		loading: previewLoading,
		error: previewError,
		data: previewData,
		refetch,
	} = useGetFinancialAccountPreviewDataQuery({
		variables: {data: {accountInfoId, accountNickname: financialAccountName, type: financialAccountType}},
	});

	if (error) throwError(error);
	if (previewError) throwError(previewError);

	function handleFormSubmit(formValues: CreateFinancialAccountInput) {
		const cleanData = CreateFinancialAccountValidationSchema.validateSync(formValues, {stripUnknown: true});
		createFinancialAccount({variables: {data: {...cleanData, accountInfoId}}})
			.then(result => {
				toast.success('Financial Account Created');
				if (result.data?.createFinancialAccount && onSave) onSave(result.data?.createFinancialAccount?.id);
			})
			.catch(throwError);
	}

	if (loading) return <Loader active />;

	return (
		<TForm<CreateFinancialAccountInput>
			onSubmit={handleFormSubmit}
			initialValues={{
				institutionId: '',
				accountNumber: '',
				accountNickname: financialAccountName,
				accountCurrency: CurrencyCountryEnum.Cad,
				type: financialAccountType,
			}}
			validationSchema={CreateFinancialAccountValidationSchema}
		>
			{({setFieldValue, fieldError, handleSubmit, values, hasErrors, hasWarnings, renderWarnings}) => {
				return (
					<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
						<Grid columns="equal">
							<FinancialAccountForm
								refetch={val =>
									refetch({
										data: {accountInfoId, accountNickname: val, type: financialAccountType},
									}).catch(e => throwError(e))
								}
								renderWarnings={renderWarnings}
								values={values}
								setFieldValue={setFieldValue}
								fieldError={fieldError}
								accountInfoId={accountInfoId}
								typeDropdownDisabled
							/>
							<GridRow>
								<GridColumn>
									<PreviewGlAccounts
										onConfirm={() => handleFormSubmit(values)}
										onCancel={() => onCancel()}
										name={values.accountNickname}
										glAccounts={previewData?.getFinancialAccountPreviewData}
										saveDisabled={!values.accountNickname || !values.accountNumber || !values.institutionId}
										warningMessage={getWarningMessage(previewData?.getFinancialAccountPreviewData, values.type)}
									/>
								</GridColumn>
							</GridRow>
						</Grid>
					</Form>
				);
			}}
		</TForm>
	);
}
