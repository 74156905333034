import {List, Divider} from 'semantic-ui-react';
import {CloneAccountButton} from './CloneAccountButton';
import {DeleteAccountButton} from './DeleteAccountButton';
import {EditAccountManager} from './EditAccountManager';
import {EditAccountName} from './EditAccountName';
import {EditAccountOrganization} from './EditAccountOrganization';
import {EditDefaultAgent} from './EditDefaultAgent';

export function PersonalAccountInfoSidebar() {
	return (
		<List>
			<Divider />
			<List.Item>
				<EditAccountName />
			</List.Item>
			<Divider />
			<List.Item>
				<EditAccountOrganization />
			</List.Item>
			<List.Item>
				<EditAccountManager />
			</List.Item>
			<List.Item>
				<EditDefaultAgent />
			</List.Item>
			<Divider />
			<List.Item>
				<CloneAccountButton />
			</List.Item>
			<List.Item>
				<DeleteAccountButton />
			</List.Item>
		</List>
	);
}
