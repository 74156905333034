import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, List, ListHeader, ListItem} from 'semantic-ui-react';
import {ContactDropdown} from '~common/components/ContactDropdown';
import {ContactTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useCreateAuthorizedRepresentativeMutation} from '../AuthorizedRepresentativeCreateForm/createAuthorizedRepresentative';

const d = debug('tacs.web.accounts.components.authorizedRepresentatives.AuthorizedRepresentativeAddSidebar');

export function AuthorizedRepresentativeAddSidebar() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {push} = useHistory();
	const [contactId, setContactId] = useState<string | undefined>();
	const {accountInfoId} = useParams<typeof routes.types.createAuthorizedRepresentative>();
	const [createAuthorizedRepresentativeMutation, {loading: mutationLoading}] = useCreateAuthorizedRepresentativeMutation();

	const onCreateAuthorizedRepresentativeClick = (val: string) => {
		createAuthorizedRepresentativeMutation({variables: {data: {contactId: val, accountInfoId}}})
			.then(() => push(routes.to.authorizedRepresentatives({accountInfoId})))
			.catch(throwError);
	};

	return (
		<List>
			<ListHeader as="h4">Add Authorized Representative From Existing Contacts</ListHeader>
			<ListItem>
				<ContactDropdown
					accountInfoId={accountInfoId}
					missingContactType={ContactTypeEnum.AuthorizedRepresentative}
					onSelect={v => {
						setContactId(v?.id);
					}}
				/>
			</ListItem>
			{contactId && (
				<>
					<ListItem>
						<Button
							disabled={mutationLoading || locked || !contactId}
							floated="right"
							positive
							onClick={() => contactId && onCreateAuthorizedRepresentativeClick(contactId)}
						>
							Save
						</Button>
						<Button type="button" floated="right" onClick={() => setContactId(undefined)}>
							Cancel
						</Button>
					</ListItem>
					<Divider />
				</>
			)}
		</List>
	);
}
