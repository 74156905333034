import {Link} from 'react-router-dom';
import {Icon, Menu} from 'semantic-ui-react';
import {useIsMobile} from '~lib/useIsMobile';
import {routes} from '../../routes';
import styles from './dashboard.module.css';

export function MymcButton() {
	const {isMobile} = useIsMobile();
	if (isMobile) {
		return (
			<Menu.Item fitted="vertically" name="home" style={{paddingLeft: 0, paddingRight: 0}}>
				<Link to={routes.to.dashboard()}>
					<Icon name="home" />
				</Link>
			</Menu.Item>
		);
	}
	return (
		<Menu.Item fitted="vertically" name="home">
			<Link to={routes.to.dashboard()} style={{fontSize: '2em'}}>
				<span className={styles.orangeText}>MYMC</span>
			</Link>
		</Menu.Item>
	);
}
