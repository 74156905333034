export const defaults = {
	assetsFolder: '/assets',
	genericImageUploadUrl: 'http://localhost:4001/upload/images',
	unsortedFileUploadUrl: 'http://localhost:4002/unsorted',
	graphql: 'http://localhost:4001/api/graphql',
	payrollSourceDeductionUrl: 'http://localhost:4001/download/sourcedeductions',
	payrollArrearsUrl: 'http://localhost:4001/download/arrears',
	payStubDownloadURL: 'http://localhost:4001/download/paystubs',
	verifyAndDecodeAgentRegistrationTokenURL: 'http://localhost:4001/decode/workstationRegistrationToken',
	verifyAndDecodeEmployeeRegistrationTokenURL: 'http://localhost:4001/decode/employeeRegistrationToken',
	linkExistingContactAndUserURL: 'http://localhost:4001/register/link-existing-contact-and-user-url',
	createAgentAndLinkToExistingUserURL: 'http://localhost:4001/register/create-workstation-and-link-to-existing-user-url',
	createUserAndLinkToContactURL: 'http://localhost:4001/register/create-user-and-link-to-contact-url',
	createAgentAndUserURL: 'http://localhost:4001/register/createWorkstationAndUserURL',
	accountRegistrationURL: 'http://localhost:4001/login/register-account',
	passwordResetEmailURL: 'http://localhost:4001/login/password-reset',
	passwordChangeURL: 'http://localhost:4001/login/password-change',
	employmentLetterSummaryUrl: 'http://localhost:4001/download/employmentlettersummary',
	maxDocUploadSizeMb: 40,
	systemAgentId: '527583bf-35bf-4f94-8157-7c24fc2e3251',
	systemUserId: '0d65f82e-4e60-4ae6-8b12-dc63294971d2',
	publicRegistrationURL: 'http://localhost:4001/register-public',
	importUploadUrl: 'http://localhost:4007/upload',
	releaseCheckPollInterval: 1000000,
	t183TypeId: '0fd41410-f59c-4fe9-98b8-502b6daa3857',
	t183VendorId: 'ad32527f-bd60-49da-9d22-b1add37b24cc',
	notificationPollingInterval: 5,
};
