import {useFetch} from '@imperium/auth-client';
import {env} from '@thx/env';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, List, Loader} from 'semantic-ui-react';
import {useCommonState} from '~common/state';
import {defaults} from '../../../defaults';
import {routes} from '../../routes';
import {SendInvitationEmailButton} from '../contacts/SendInvitationEmailButton';
import {SendPasswordResetEmailButton} from '../contacts/SendPasswordResetEmailButton';
import {useGetEmployeeFormEmployeeQuery} from './EmployeeEditForm/getEmployeeFormEmployee';
import {EndEmploymentForm} from './EndEmploymentForm';

const d = debug('tacs.web.accounts.components.employees.EmployeeViewSidebar');

export function EmployeeViewSidebar() {
	const {employeeId, accountInfoId} = useParams<typeof routes.types.viewEmployee>();
	const {push} = useHistory();
	const {startDate} = useCommonState();
	const fetch = useFetch();
	const employmentLetterSummaryUrl = env.getString('employmentLetterSummaryUrl', defaults.employmentLetterSummaryUrl);

	const {data, loading, error} = useGetEmployeeFormEmployeeQuery({
		variables: {id: employeeId},
	});

	if (loading) return <Loader active />;
	if (error) throw error;
	if (!data) throw new Error("The Data couldn't be loaded");

	// download employment letter
	const downloadEmploymentLetter = async () => {
		const url = `${employmentLetterSummaryUrl}?id=${employeeId}&year=${startDate.year()}`;
		fetch(url)
			.then(response => response.blob())
			.then(responseData => {
				if (responseData) {
					const blob = new Blob([responseData], {type: 'application/pdf'});
					window.open(window.URL.createObjectURL(blob));
				}
			})
			.catch(err => {
				throw Error(err.message);
			});
	};

	return (
		<List>
			<List.Item>
				<SendInvitationEmailButton contactId={data.getEmployeeById.contact.id} />
			</List.Item>
			<List.Item>
				<SendPasswordResetEmailButton email={data.getEmployeeById.contact.authorizedUser?.email} />
			</List.Item>
			<Divider />
			<List.Item>
				<Button fluid color="purple" onClick={() => downloadEmploymentLetter()}>
					Download Employment Letter
				</Button>
			</List.Item>
			<Divider />
			<List.Item>
				<Button fluid color="orange" onClick={() => push(routes.to.editEmployee({accountInfoId, employeeId}))}>
					Edit Employee
				</Button>
			</List.Item>
			<List.Item>
				<EndEmploymentForm employeeId={employeeId} />
			</List.Item>
		</List>
	);
}
