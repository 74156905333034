import {useState, KeyboardEvent} from 'react';
import {useHistory} from 'react-router-dom';
import {Input, MenuItem, Popup} from 'semantic-ui-react';
import {useIsMobile} from '~lib/useIsMobile';
import {routes} from '../routes';

export function SearchBar() {
	const {push} = useHistory();
	const {isMobile} = useIsMobile();
	const [open, setOpen] = useState(false);

	return (
		<>
			{isMobile && (
				<Popup
					inverted
					on={['click']}
					onClose={() => setOpen(false)}
					onOpen={() => setOpen(true)}
					content={
						<Input
							icon="search"
							iconPosition="left"
							placeholder="Search..."
							size="small"
							onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
								if (e.key === 'Enter' && e.currentTarget.value.length >= 1) {
									setOpen(false);
									push(routes.to.searchResultsPage({query: e.currentTarget.value}));
								}
							}}
						/>
					}
					open={open}
					trigger={<MenuItem icon="search" />}
				/>
			)}
			{!isMobile && (
				<MenuItem>
					<Input
						icon="search"
						iconPosition="left"
						placeholder="Search..."
						size="small"
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
							if (e.key === 'Enter' && e.currentTarget.value.length >= 1) {
								push(routes.to.searchResultsPage({query: e.currentTarget.value}));
							}
						}}
					/>
				</MenuItem>
			)}
		</>
	);
}
