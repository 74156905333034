import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getPayStubsSidebar.graphql';

const defaultOptions = {} as const;
export type GetPayStubsSidebarQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter?: Types.InputMaybe<Types.PayStubsFilterInput>;
}>;

export type GetPayStubsSidebarQuery = {
	getPayStubsByAccount?: Array<{
		id: string;
		payDate: LocalDate;
		startDate: LocalDate;
		endDate: LocalDate;
		grossIncome: Array<{id: string; amount: Money}>;
	}> | null;
};

export type GetPayStubsSidebarType = {
	id: string;
	payDate: LocalDate;
	startDate: LocalDate;
	endDate: LocalDate;
	grossIncome: Array<{id: string; amount: Money}>;
};

/**
 * __useGetPayStubsSidebarQuery__
 *
 * To run a query within a React component, call `useGetPayStubsSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayStubsSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayStubsSidebarQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPayStubsSidebarQuery(baseOptions: Apollo.QueryHookOptions<GetPayStubsSidebarQuery, GetPayStubsSidebarQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPayStubsSidebarQuery, GetPayStubsSidebarQueryVariables>(Operations, options);
}
export function useGetPayStubsSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayStubsSidebarQuery, GetPayStubsSidebarQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetPayStubsSidebarQuery, GetPayStubsSidebarQueryVariables>(Operations, options);
}
export type GetPayStubsSidebarQueryHookResult = ReturnType<typeof useGetPayStubsSidebarQuery>;
export type GetPayStubsSidebarLazyQueryHookResult = ReturnType<typeof useGetPayStubsSidebarLazyQuery>;
export type GetPayStubsSidebarQueryResult = Apollo.QueryResult<GetPayStubsSidebarQuery, GetPayStubsSidebarQueryVariables>;
