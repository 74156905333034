import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetOrganizationDropdownQuery} from '~common/components/OrganizationDropdown/getOrganizationDropdown';

const d = debug('tacs.web.common.components.OrganizationDropdown');

interface OrganizationDropdownProps extends Omit<DropdownProps, 'options' | 'onChange'> {
	onChange?: (organizationId?: string) => void;
}

export function OrganizationDropdown(props: OrganizationDropdownProps) {
	const {data, loading: queryLoading, error} = useGetOrganizationDropdownQuery();
	const {onChange, loading, ...rest} = props;

	function handleChange(val?: string) {
		if (onChange) onChange(val);
	}

	const options = data?.getOrganizations?.map(organization => {
		return {key: organization.id, value: organization.id, text: organization.name};
	});

	return (
		<Dropdown
			{...rest}
			search
			error={!!error}
			loading={loading || queryLoading}
			options={options || []}
			onChange={(e, val) => handleChange(val.value?.toString())}
		/>
	);
}
