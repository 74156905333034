import {formatDate} from '@thx/date';
import {Card, CardContent, CardDescription, CardHeader, Divider, Icon, List, Placeholder} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {getFullName} from '~lib/getFullName';
import type {GetSelfEmployedAccountInfoInformationQuery} from './getSelfEmployedAccountInfoInformation';

interface Props {
	data: GetSelfEmployedAccountInfoInformationQuery;
}

export function SelfEmployedOverviewCard({data}: Props) {
	if (!data?.getSelfEmployedAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);

	const {accountInfo, yearEnd, gstFilingFrequency, communicationCode, businessNumber, businessStartDate} = data.getSelfEmployedAccountByAccountInfoId;
	const communicationEmail = communicationCode
		? `mymc-${accountInfo.friendlyId.toString().padStart(4, '0')}${communicationCode}@makingyourmilescount.com`
		: undefined;

	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="clipboard" />
					Details
				</CardHeader>
				<Divider />
				<CardDescription>
					<List>
						<List.Item>
							<ColoredSpan labelText="Name" contentText={data.getSelfEmployedAccountByAccountInfoId.accountInfo?.name} copyTextOnClick />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Business Start Date" contentText={formatDate(businessStartDate)} copyTextOnClick />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Business Number" contentText={businessNumber} copyTextOnClick />
						</List.Item>
						<List.Item>
							<ColoredSpan
								labelText="Address"
								copyTextOnClick
								contentText={[
									data?.getSelfEmployedAccountByAccountInfoId.accountInfo?.address?.lineOne,
									data?.getSelfEmployedAccountByAccountInfoId.accountInfo?.address?.lineTwo,
									data?.getSelfEmployedAccountByAccountInfoId.accountInfo?.address?.city,
									data?.getSelfEmployedAccountByAccountInfoId.accountInfo?.address?.province,
									data?.getSelfEmployedAccountByAccountInfoId.accountInfo?.address?.postalCode,
								]
									.filter(value => value)
									.join(', ')}
							/>
						</List.Item>
						<List.Item>
							{communicationEmail && <ColoredSpan labelText="Communication Email" copyTextOnClick contentText={communicationEmail} />}
						</List.Item>
						<List.Item />
						<List.Item>
							<ColoredSpan labelText="Year End" contentText={formatDate(yearEnd, {date: true, type: 'long', format: 'MMMM dd'})} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="GST Filing Frequency" contentText={gstFilingFrequency || ''} />
						</List.Item>
						<List.Item />
						<List.Item>
							<ColoredSpan labelText="Manager" contentText={getFullName(accountInfo?.manager)} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Default Agent" contentText={getFullName(accountInfo?.defaultAgent)} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Status" contentText={accountInfo.contracts?.find(c => c.authorized && !c.endDate) ? 'Active' : 'Archived'} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Organization" contentText={accountInfo?.organization?.name} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Office" contentText={accountInfo?.defaultAgent?.office?.name} />
						</List.Item>
					</List>
				</CardDescription>
			</CardContent>
		</Card>
	);
}
