import {useState} from 'react';
import {Modal, type SemanticICONS, Loader} from 'semantic-ui-react';
import {PreviewGlAccounts} from '~common/components/PreviewGlAccounts/PreviewGlAccounts';
import {FinancialAccountTypeEnum} from '~core/graphql';
import type * as Types from '~core/graphql';

type PreviewGlAccountType = Array<{type: Types.GeneralLedgerAccountTypeEnum; name: string; code: number}> | null | undefined;

interface PreviewModalProps {
	onConfirm: () => void;
	headerText?: string;
	trigger: JSX.Element;
	icon?: SemanticICONS;
	accountType?: FinancialAccountTypeEnum;
	name: string;
	headerName?: string;
	details?: {name: string; description?: string | null}[];
	glAccounts?: PreviewGlAccountType;
}

export function getWarningMessage(glAccounts: PreviewGlAccountType, type?: FinancialAccountTypeEnum) {
	if (FinancialAccountTypeEnum.CreditCard === type) {
		return 'Credit Cards will not create a General Ledger Account';
	}
	if (glAccounts?.length === 2 && FinancialAccountTypeEnum.BankAccount === type) {
		return 'Since this is the first Bank Account, it will be set as the Default Bank.';
	}
	return undefined;
}

export function PreviewGlAccountsModal({
	accountType,
	trigger,
	onConfirm,
	headerText,
	icon,
	name,
	headerName,
	details,
	glAccounts,
}: PreviewModalProps) {
	const [open, setOpen] = useState(false);

	return (
		<Modal open={open} size="small" dimmer="blurring" trigger={trigger} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
			<Modal.Content scrolling>
				{!glAccounts ? (
					<Loader Active />
				) : (
					<PreviewGlAccounts
						onConfirm={() => {
							onConfirm();
							setOpen(false);
						}}
						onCancel={() => setOpen(false)}
						headerText={headerText}
						icon={icon}
						name={name}
						headerName={headerName}
						details={details}
						glAccounts={glAccounts}
						warningMessage={getWarningMessage(glAccounts, accountType)}
					/>
				)}
			</Modal.Content>
		</Modal>
	);
}
