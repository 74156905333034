import {List, Divider} from 'semantic-ui-react';
import {CloneAccountButton} from './CloneAccountButton';
import {DeleteAccountButton} from './DeleteAccountButton';
import {EditAccountManager} from './EditAccountManager';
import {EditAccountName} from './EditAccountName';
import {EditAccountOrganization} from './EditAccountOrganization';
import {EditAccountRating} from './EditAccountRating';
import {EditAddress} from './EditAddress';
import {EditBusinessNumber} from './EditBusinessNumber';
import {EditDefaultAgent} from './EditDefaultAgent';
import {EditFilingFrequency} from './EditFilingFrequency';
import {EditIncorporationDate} from './EditIncorporationDate';
import {EditYearEnd} from './EditYearEnd';
import {ResetAccountingDataButton} from './ResetAccountingDataButton';

export function CorporateAccountInfoSidebar() {
	return (
		<List>
			<List.Item>
				<EditAccountName />
			</List.Item>
			<List.Item>
				<EditBusinessNumber />
			</List.Item>
			<List.Item>
				<EditIncorporationDate />
			</List.Item>
			<List.Item>
				<EditYearEnd />
			</List.Item>
			<List.Item>
				<EditFilingFrequency />
			</List.Item>
			<List.Item>
				<EditAddress />
			</List.Item>
			<Divider />
			<List.Item>
				<EditAccountOrganization />
			</List.Item>
			<List.Item>
				<EditAccountManager />
			</List.Item>
			<List.Item>
				<EditDefaultAgent />
			</List.Item>
			<List.Item>
				<EditAccountRating />
			</List.Item>
			<Divider />
			<List.Item>
				<CloneAccountButton />
			</List.Item>
			<List.Item>
				<ResetAccountingDataButton />
			</List.Item>
			<List.Item>
				<DeleteAccountButton />
			</List.Item>
		</List>
	);
}
