import * as Apollo from '@apollo/client';

import Operations from '~common/components/ContactDropdown/getContactsWithMIssingContactType.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetContactsWithMissingContactTypeQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	missingContactType: Types.ContactTypeEnum;
}>;

export type GetContactsWithMissingContactTypeQuery = {
	getContactsByAccountInfoId?: Array<{id: string; version: number; givenName: string; surname: string; preferredName?: string | null}> | null;
};

export type GetContactsWithMissingContactTypeType = {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};

/**
 * __useGetContactsWithMissingContactTypeQuery__
 *
 * To run a query within a React component, call `useGetContactsWithMissingContactTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsWithMissingContactTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsWithMissingContactTypeQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      missingContactType: // value for 'missingContactType'
 *   },
 * });
 */
export function useGetContactsWithMissingContactTypeQuery(
	baseOptions: Apollo.QueryHookOptions<GetContactsWithMissingContactTypeQuery, GetContactsWithMissingContactTypeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetContactsWithMissingContactTypeQuery, GetContactsWithMissingContactTypeQueryVariables>(Operations, options);
}
export function useGetContactsWithMissingContactTypeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContactsWithMissingContactTypeQuery, GetContactsWithMissingContactTypeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetContactsWithMissingContactTypeQuery, GetContactsWithMissingContactTypeQueryVariables>(Operations, options);
}
export type GetContactsWithMissingContactTypeQueryHookResult = ReturnType<typeof useGetContactsWithMissingContactTypeQuery>;
export type GetContactsWithMissingContactTypeLazyQueryHookResult = ReturnType<typeof useGetContactsWithMissingContactTypeLazyQuery>;
export type GetContactsWithMissingContactTypeQueryResult = Apollo.QueryResult<
	GetContactsWithMissingContactTypeQuery,
	GetContactsWithMissingContactTypeQueryVariables
>;
