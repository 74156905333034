import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getBasicAccountInfoInformation.graphql';

const defaultOptions = {} as const;
export type GetBasicAccountInfoInformationQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetBasicAccountInfoInformationQuery = {
	getBasicAccountByAccountInfoId?: {
		id: string;
		version: number;
		accountInfo: {
			id: string;
			version: number;
			name: string;
			friendlyId: number;
			manager?: {id: string; version: number; surname: string; givenName: string} | null;
			defaultAgent?: {id: string; version: number; surname: string; givenName: string; office: {id: string; name: string}} | null;
			contracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null}> | null;
			organization?: {id: string; name: string} | null;
		};
	} | null;
};

export type GetBasicAccountInfoInformationType = {
	id: string;
	version: number;
	accountInfo: {
		id: string;
		version: number;
		name: string;
		friendlyId: number;
		manager?: {id: string; version: number; surname: string; givenName: string} | null;
		defaultAgent?: {id: string; version: number; surname: string; givenName: string; office: {id: string; name: string}} | null;
		contracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null}> | null;
		organization?: {id: string; name: string} | null;
	};
};

/**
 * __useGetBasicAccountInfoInformationQuery__
 *
 * To run a query within a React component, call `useGetBasicAccountInfoInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicAccountInfoInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicAccountInfoInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasicAccountInfoInformationQuery(
	baseOptions: Apollo.QueryHookOptions<GetBasicAccountInfoInformationQuery, GetBasicAccountInfoInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetBasicAccountInfoInformationQuery, GetBasicAccountInfoInformationQueryVariables>(Operations, options);
}
export function useGetBasicAccountInfoInformationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetBasicAccountInfoInformationQuery, GetBasicAccountInfoInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetBasicAccountInfoInformationQuery, GetBasicAccountInfoInformationQueryVariables>(Operations, options);
}
export type GetBasicAccountInfoInformationQueryHookResult = ReturnType<typeof useGetBasicAccountInfoInformationQuery>;
export type GetBasicAccountInfoInformationLazyQueryHookResult = ReturnType<typeof useGetBasicAccountInfoInformationLazyQuery>;
export type GetBasicAccountInfoInformationQueryResult = Apollo.QueryResult<
	GetBasicAccountInfoInformationQuery,
	GetBasicAccountInfoInformationQueryVariables
>;
