import {Link} from 'react-router-dom';
import {Icon, Menu} from 'semantic-ui-react';
import {useIsMobile} from '~lib/useIsMobile';
import {useAccountState} from '../../accounts/state';
import {routes} from '../routes';
import {useAccountingState} from '../state';

export function HistoricDataEntryMode() {
	const {accountInfoId} = useAccountState();
	const {historicClosingDate} = useAccountingState();
	const {isMobile} = useIsMobile();

	if (historicClosingDate || isMobile) {
		return null;
	}
	return (
		<Link to={routes.to.openingBalances({accountInfoId: accountInfoId || ''})}>
			<Menu.Item style={{backgroundColor: '#f2711c', fontWeight: 'bold'}}>
				<Icon>
					<svg style={{marginTop: '-3px', marginLeft: '-2px'}} width="20px" height="20px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
						<path
							fill="#ffffff"
							d="M470.7 20L368.2 49.81l41.5-28.09c-26.2 5.92-59.3 17.5-100.9 36.19l-67.9 70.79L265 79.25c-23.3 12.96-48 29.95-71.8 49.85l-15.8 64.3-3.4-47.6c-23.5 21.6-45.6 45.6-63.9 70.9-19.23 26.5-34.26 54.5-41.79 82.4l-28.12-18.8c2.52 23.7 10.31 44.3 23.09 63.2l-33.62-10.3c7.64 23.5 20.13 38.7 41.25 51-11.83 33.3-17.38 68.1-23.34 102.8l18.4 3.1C87.31 277.4 237.9 141.8 374 81.72l6.9 17.38c-121.7 54.5-216.3 146.5-265.8 279.1 18.1.1 35.8-2.1 52.2-6.3l4.9-60.9 13.1 55.5c10.9-4 20.9-8.8 29.8-14.4l-20.7-43.5 32.8 34.8c8-6.4 14.6-13.6 19.6-21.5 30.4-47.5 62.2-94.7 124.8-134.2l-45.7-16.2 70.1 2.1c11.4-5.8 23.4-12.9 32.5-19.6l-49.7-4 74.7-17.6c5.8-5.8 11.2-11.9 16.1-18 17.3-21.94 29-44.78 26.2-65.55-1.3-10.39-7.5-20.16-17.6-25.63-2.5-1.3-5.2-2.45-7.5-3.22z"
						/>
					</svg>
				</Icon>
				Active: Historical Mode
			</Menu.Item>
		</Link>
	);
}
