import {env} from '@thx/env';
import {Menu} from 'semantic-ui-react';
import {useIsMobile} from '~lib/useIsMobile';

export function Development() {
	const isDev = env.isDevelopment();
	const isProd = env.isProduction();
	const {isMobile} = useIsMobile();
	if (isMobile) return null;

	if (isDev) {
		return <Menu.Item style={{backgroundColor: '#db2828', fontWeight: 'bold', borderRadius: 0}}>Development</Menu.Item>;
	}
	if (isProd && window.location.href.includes('staging.mymc.app')) {
		return <Menu.Item style={{backgroundColor: '#db7328', fontWeight: 'bold', borderRadius: 0}}>Staging</Menu.Item>;
	}
	return null;
}
