import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	businessReport: {
		path: '/account/:accountInfoId/self-employed/business-report',
		params: ['accountInfoId'] as const,
	},
	payStatements: {
		path: '/account/:accountInfoId/self-employed/pay-statements',
		params: ['accountInfoId'] as const,
	},
	addPayStatement: {
		path: '/account/:accountInfoId/self-employed/pay-statement/add',
		params: ['accountInfoId'] as const,
	},
	editPayStatement: {
		path: '/account/:accountInfoId/self-employed/pay-statement/edit/:payStatementId',
		params: ['accountInfoId', 'payStatementId'] as const,
	},
	expenses: {
		path: '/account/:accountInfoId/self-employed/expenses',
		params: ['accountInfoId'] as const,
	},
	chartOfAccounts: {
		path: '/account/:accountInfoId/settings/chart-of-accounts',
		params: ['accountInfoId'] as const,
	},
	chartOfAccountsTemplatesList: {
		path: '/admin/chart-of-accounts-templates',
		exact: true,
	},
	chartOfAccountsTemplates: {
		path: '/admin/chart-of-accounts-templates/:chartOfAccountsTemplateId',
		params: ['chartOfAccountsTemplateId'] as const,
	},
	editGeneralLedgerAccountTemplate: {
		path: '/admin/chart-of-accounts-templates/:chartOfAccountsTemplateId/general-ledger-account/:generalLedgerAccountTemplateId/edit',
		params: ['chartOfAccountsTemplateId', 'generalLedgerAccountTemplateId'] as const,
	},
	// generalJournalEntryRoutes
	createGeneralJournalEntry: {
		path: '/account/:accountInfoId/accounting/general-journal/create',
		params: ['accountInfoId'] as const,
	},
	editGeneralJournalEntry: {
		path: '/account/:accountInfoId/accounting/general-journal/:journalEntryId/edit',
		params: ['accountInfoId', 'journalEntryId'] as const,
	},
	generalJournal: {
		path: '/account/:accountInfoId/accounting/general-journal',
		params: ['accountInfoId'] as const,
	},
	generalLedger: {
		path: '/account/:accountInfoId/accounting/general-ledger',
		params: ['accountInfoId'] as const,
	},
	// purchase journal
	payables: {
		path: '/account/:accountInfoId/accounting/payables',
		params: ['accountInfoId'] as const,
	},
	createPayable: {
		path: '/account/:accountInfoId/accounting/payable/create',
		params: ['accountInfoId'] as const,
	},
	editPayable: {
		path: '/account/:accountInfoId/accounting/payable/:payableId/edit',
		params: ['accountInfoId', 'payableId'] as const,
	},
	// payment
	payments: {
		path: '/account/:accountInfoId/accounting/payments',
		params: ['accountInfoId'] as const,
	},
	createPayment: {
		path: '/account/:accountInfoId/accounting/payment/create',
		params: ['accountInfoId'] as const,
	},
	editPayment: {
		path: '/account/:accountInfoId/accounting/payment/:paymentId/edit',
		params: ['accountInfoId', 'paymentId'] as const,
	},
	// receivable
	receivables: {
		path: '/account/:accountInfoId/accounting/receivables',
		params: ['accountInfoId'] as const,
	},
	createReceivable: {
		path: '/account/:accountInfoId/accounting/receivable/create',
		params: ['accountInfoId'] as const,
	},
	editReceivable: {
		path: '/account/:accountInfoId/accounting/receivable/:receivableId/edit',
		params: ['accountInfoId', 'receivableId'] as const,
	},
	// receipt
	receipts: {
		path: '/account/:accountInfoId/accounting/receipts',
		params: ['accountInfoId'] as const,
	},
	createReceipt: {
		path: '/account/:accountInfoId/accounting/receipt/create',
		params: ['accountInfoId'] as const,
	},
	editReceipt: {
		path: '/account/:accountInfoId/accounting/receipt/:receiptId/edit',
		params: ['accountInfoId', 'receiptId'] as const,
	},
	incomeStatement: {
		path: '/account/:accountInfoId/accounting/income-statement',
		params: ['accountInfoId'] as const,
	},
	balanceSheet: {
		path: '/account/:accountInfoId/accounting/balance-sheet',
		params: ['accountInfoId'] as const,
	},
	// opening balance
	openingBalances: {
		path: '/account/:accountInfoId/accounting/opening-balances',
		params: ['accountInfoId'] as const,
	},
	// vendors
	vendors: {
		path: '/account/:accountInfoId/vendors',
		params: ['accountInfoId'] as const,
		exact: true,
	},
	vendorsEdit: {
		path: '/account/:accountInfoId/vendors/:vendorId/edit',
		params: ['accountInfoId', 'vendorId'] as const,
	},
	vendorsAdd: {
		path: '/account/:accountInfoId/vendors/add',
		params: ['accountInfoId'] as const,
	},
	vendorsMerge: {
		path: '/account/:accountInfoId/vendors/merge',
		params: ['accountInfoId'] as const,
	},
	import: {
		path: '/account/:accountInfoId/accounting/import',
		params: ['accountInfoId'] as const,
	},
	importJob: {
		path: '/account/:accountInfoId/accounting/import/job/:jobId',
		params: ['accountInfoId', 'jobId'] as const,
	},
	importUpload: {
		path: '/account/:accountInfoId/accounting/import/upload',
		params: ['accountInfoId'] as const,
	},
	// year end
	closeAcFiscalYear: {
		path: '/account/:accountInfoId/accounting/close-year',
		params: ['accountInfoId'] as const,
	},
	// templates
	journalEntryTemplates: {
		path: '/account/:accountInfoId/accounting/journalEntryTemplates',
		params: ['accountInfoId'] as const,
	},
	createJournalEntryTemplate: {
		path: '/account/:accountInfoId/accounting/journalEntryTemplate/create',
		params: ['accountInfoId'] as const,
	},
	editJournalEntryTemplate: {
		path: '/account/:accountInfoId/accounting/journalEntryTemplate/:journalEntryTemplateId/edit',
		params: ['accountInfoId', 'journalEntryTemplateId'] as const,
	},
	payableTemplates: {
		path: '/account/:accountInfoId/accounting/payableTemplates',
		params: ['accountInfoId'] as const,
	},
	createPayableTemplate: {
		path: '/account/:accountInfoId/accounting/payableTemplate/create',
		params: ['accountInfoId'] as const,
	},
	editPayableTemplate: {
		path: '/account/:accountInfoId/accounting/payableTemplate/:payableTemplateId/edit',
		params: ['accountInfoId', 'payableTemplateId'] as const,
	},
	receivableTemplates: {
		path: '/account/:accountInfoId/accounting/receivableTemplates',
		params: ['accountInfoId'] as const,
	},
	createReceivableTemplate: {
		path: '/account/:accountInfoId/accounting/receivableTemplate/create',
		params: ['accountInfoId'] as const,
	},
	editReceivableTemplate: {
		path: '/account/:accountInfoId/accounting/receivableTemplate/:receivableTemplateId/edit',
		params: ['accountInfoId', 'receivableTemplateId'] as const,
	},
});
