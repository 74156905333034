import {TForm} from '@thx/controls';
import debug from 'debug';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Divider, Form, FormInput} from 'semantic-ui-react';
import {FinancialAccountTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../../accounts/state';
import {FinancialAccountsDropdown} from '../../../../documents/components/statement/FinancialAccountsDropdown';
import {useEditDefaultBankAccountMutation} from './editDefaultBankAccount';

const d = debug('tacs.web.accounting.components.chartOfAccounts.ChooseDefaultBankAccount');

interface ChooseLinkedAccountProps {
	queryLoading: boolean;
	accountInfoId: string;
	defaultBankAccountId?: string | null;
}

export function ChooseDefaultBankAccount({queryLoading, accountInfoId, defaultBankAccountId}: ChooseLinkedAccountProps) {
	const {locked} = useAccountState();
	const throwError = useAsyncError();
	const [editing, setEditing] = useState(false);
	const [editLinkedMutation, {loading: mutationLoading}] = useEditDefaultBankAccountMutation();

	const chooseButton = (
		<Button
			loading={queryLoading}
			fluid
			color={defaultBankAccountId ? 'blue' : 'orange'}
			onClick={() => {
				setEditing(true);
			}}
		>
			Edit Default Bank Account
		</Button>
	);

	if (!editing) return chooseButton;

	return (
		<TForm<{defaultBankAccountId: string}>
			initialValues={{defaultBankAccountId: defaultBankAccountId || ''}}
			onSubmit={values => {
				editLinkedMutation({
					variables: {
						data: {
							depositAccountId: values.defaultBankAccountId,
							accountInfoId,
						},
					},
				})
					.then(() => {
						toast.success('Linked account saved');
						setEditing(false);
					})
					.catch(throwError);
			}}
		>
			{({handleSubmit, setFieldValue, values, fieldError}) => (
				<Form onSubmit={handleSubmit}>
					<FormInput autoFocus error={fieldError('glAccount')} label="Select Default Bank Account">
						<FinancialAccountsDropdown
							passInGlAccIdAsValue
							value={values.defaultBankAccountId}
							addNewFinancialAccountButton
							accountInfoId={accountInfoId}
							financialAccountsType={FinancialAccountTypeEnum.BankAccount}
							onChange={val => setFieldValue('glAccount', val?.glAccount?.id)}
						/>
					</FormInput>
					<Button disabled={mutationLoading || locked} positive floated="right" type="submit">
						Save
					</Button>
					<Button type="button" onClick={() => setEditing(false)}>
						Cancel
					</Button>
					<Divider />
				</Form>
			)}
		</TForm>
	);
}
