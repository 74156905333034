import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAccountOrganization.graphql';

const defaultOptions = {} as const;
export type GetEditAccountOrganizationQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditAccountOrganizationQuery = {
	getAccountInfoById?: {id: string; version: number; organization?: {id: string; version: number; name: string} | null} | null;
};

export type GetEditAccountOrganizationType = {id: string; version: number; organization?: {id: string; version: number; name: string} | null};

/**
 * __useGetEditAccountOrganizationQuery__
 *
 * To run a query within a React component, call `useGetEditAccountOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAccountOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAccountOrganizationQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditAccountOrganizationQuery(
	baseOptions: Apollo.QueryHookOptions<GetEditAccountOrganizationQuery, GetEditAccountOrganizationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAccountOrganizationQuery, GetEditAccountOrganizationQueryVariables>(Operations, options);
}
export function useGetEditAccountOrganizationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAccountOrganizationQuery, GetEditAccountOrganizationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAccountOrganizationQuery, GetEditAccountOrganizationQueryVariables>(Operations, options);
}
export type GetEditAccountOrganizationQueryHookResult = ReturnType<typeof useGetEditAccountOrganizationQuery>;
export type GetEditAccountOrganizationLazyQueryHookResult = ReturnType<typeof useGetEditAccountOrganizationLazyQuery>;
export type GetEditAccountOrganizationQueryResult = Apollo.QueryResult<GetEditAccountOrganizationQuery, GetEditAccountOrganizationQueryVariables>;
