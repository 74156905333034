import debug from 'debug';
import {useParams} from 'react-router-dom';
import {List} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import type {GeneralLedgerAccount} from '~core/graphql';
import {formatGlAccount} from '~lib/formatGlAccount';
import type {routes} from '../../routes';
import {useGetGeneralLedgerAccountsTemplateTableTableQuery} from './GeneralLedgerAccountsTemplateTable/getGeneralLedgerAccountsTemplateTable';

const d = debug('tacs.web.accounting.components.chartOfAccountsTemplates.GeneralLedgerAccountsTemplateTableSideBar');

export function GeneralLedgerAccountsTemplateTableSideBar() {
	const {chartOfAccountsTemplateId} = useParams<typeof routes.types.chartOfAccountsTemplates>();
	const {data, error} = useGetGeneralLedgerAccountsTemplateTableTableQuery({variables: {chartOfAccountsTemplateId}});

	if (error) throw error;

	function objectType(object: any): object is GeneralLedgerAccount {
		return typeof object?.id === 'string' && typeof object?.code === 'number';
	}

	return (
		<List>
			{Object.values(data?.getChartOfAccountsTemplateById || {})?.map((item: GeneralLedgerAccount | unknown) => {
				if (objectType(item)) {
					return (
						<List.Item key={item.id}>
							<ColoredSpan labelText={item.name} contentText={formatGlAccount(item) || 'Not Set'} />
						</List.Item>
					);
				}
				return null;
			})}
		</List>
	);
}
