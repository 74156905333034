import debug from 'debug';
import {useParams} from 'react-router-dom';
import {List, Loader} from 'semantic-ui-react';
import type {routes} from '../../routes';
import {SendInvitationEmailButton} from '../contacts/SendInvitationEmailButton';
import {SendPasswordResetEmailButton} from '../contacts/SendPasswordResetEmailButton';
import {useGetEmployeeFormEmployeeQuery} from './EmployeeEditForm/getEmployeeFormEmployee';
import {EndEmploymentForm} from './EndEmploymentForm';

const d = debug('tacs.web.accounts.components.employees.EmployeeEditSidebar');

export function EmployeeEditSidebar() {
	const {employeeId: paramEmployeeId} = useParams<typeof routes.types.editEmployee>();
	const employeeId = paramEmployeeId || '';

	const {data, loading, error} = useGetEmployeeFormEmployeeQuery({
		variables: {id: employeeId},
	});

	if (loading) return <Loader active />;
	if (error) throw error;
	if (!data) throw new Error("The Data couldn't be loaded");

	return (
		<List>
			<List.Item>
				<EndEmploymentForm employeeId={employeeId} />
			</List.Item>
			<List.Item>
				<SendInvitationEmailButton contactId={data.getEmployeeById.contact.id} />
			</List.Item>
			<List.Item>
				<SendPasswordResetEmailButton email={data.getEmployeeById.contact.authorizedUser?.email} />
			</List.Item>
		</List>
	);
}
