import {MoneyInput} from '@thx/controls';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Header} from 'semantic-ui-react';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import {routes} from '../../routes';
import {clearPayableTemplatesFilter, setPayableTemplatesFilter, useAccountingState} from '../../state';

export function PayableTemplatesTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.payableTemplates>();
	const {vendor, fromAmount, toAmount} = useAccountingState().payableTemplatesFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();
	return (
		<List>
			<List.Item>
				<Button positive fluid onClick={() => push(routes.to.createPayableTemplate({accountInfoId}))}>
					Create PayableTemplate
				</Button>
			</List.Item>
			<Divider />
			<Header size="small">Filter PayableTemplates</Header>
			<List.Item>
				<Label>Vendor</Label>
				<VendorDropdown
					onChange={val => dispatch(setPayableTemplatesFilter({vendor: val}))}
					fluid
					tabIndex={0}
					value={vendor || ''}
					accountInfoId={accountInfoId}
				/>
			</List.Item>
			<List.Item>
				<Label>From Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => value && dispatch(setPayableTemplatesFilter({fromAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Label>To Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="To Amount"
					value={toAmount}
					onChange={value => value && dispatch(setPayableTemplatesFilter({toAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Button content="Clear Selection" onClick={() => dispatch(clearPayableTemplatesFilter())} />
			</List.Item>
		</List>
	);
}
