import {formatMoney} from '@thx/money';
import {Link} from 'react-router-dom';
import {Card, CardContent, CardDescription, CardHeader, Divider, Icon, List, ListItem, ListList, Placeholder} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {getFullName} from '~lib/getFullName';
import {routes} from '../../../../receivables/routes';
import {useGetActiveContractsQuery} from './getActiveContracts';

interface Props {
	accountInfoId: string;
}

export function ContractCard({accountInfoId}: Props) {
	const {data, loading, error} = useGetActiveContractsQuery({variables: {accountInfoId}});
	if (error) throw error;

	if (!data?.getActiveContractsByAccountInfoId || loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="money" />
					Contracts
				</CardHeader>
				<Divider />
				<CardDescription>
					{data?.getActiveContractsByAccountInfoId.map(contract => {
						return (
							<List key={contract.id}>
								<ListItem>
									<ColoredSpan labelText="Contract" contentText={contract.name} />
								</ListItem>
								<ListItem>
									<ColoredSpan labelText="Fee" contentText={formatMoney(contract.fee?.total) || 'None'} />
								</ListItem>
								<ListItem>
									<ColoredSpan labelText="Authorized By" contentText={getFullName(contract.authorizedBy)} />
								</ListItem>
								{(contract.subContracts?.length || 0) > 0 && <ColoredSpan labelText="Sub Contracts" />}
								<ListItem>
									<ListList>
										{contract.subContracts?.map(subContract => {
											if (subContract.authorized && subContract.endDate === null) {
												return (
													<ListItem key={subContract.id}>
														<ListItem>
															<Link to={routes.to.contractEdit({accountInfoId: subContract.accountInfo.id, contractId: subContract.id})}>
																<ColoredSpan labelText={subContract.accountInfo.name} contentText={subContract.name} />
															</Link>
														</ListItem>
													</ListItem>
												);
											}
											return null;
										})}
									</ListList>
								</ListItem>
								<ListItem>
									<Link to={routes.to.contractEdit({accountInfoId, contractId: contract.id})}>View Details...</Link>
								</ListItem>
							</List>
						);
					})}
				</CardDescription>
			</CardContent>
		</Card>
	);
}
