import {TForm} from '@thx/controls';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Dropdown, Form, FormInput, List, ListHeader, ListItem} from 'semantic-ui-react';
import {object, string} from 'yup';
import {ContactDropdown} from '~common/components/ContactDropdown';
import {ContactTypeEnum, EmployeePaymentIntervalEnum, employeePaymentIntervalEnumLookup, provinceEnumLookup} from '~core/graphql';
import {getProvinces} from '~lib/getProvinces';
import {useAsyncError} from '~lib/useAsyncError';
import {useCreateEmployeeMutation} from '../../../lib/createEmployee';
import {getPaymentIntervalOptions} from '../../../lib/getPaymentItervalOptions';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';

const d = debug('tacs.web.accounts.components.employees.EmployeeAddSidebarExistingContactList');

const employeeAddSidebarContactListValidation = object().shape({
	contactId: string().required('The contact id is required'),
	homeTerminal: string().required('The home terminal is required.'),
	paymentInterval: string().required('The payment interval is required.'),
});

interface EmployeeAddSidebarContactListValidationSchemaType {
	contactId: string;
	homeTerminal: string;
	paymentInterval: string;
}
export function EmployeeAddSidebarExistingContactList() {
	const {accountInfoId} = useParams<typeof routes.types.employees>();
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {push} = useHistory();

	const [createEmployeeMutation, {loading: mutationLoading}] = useCreateEmployeeMutation();

	const onCreateEmployeeClick = (contactId: string, paymentInterval: string, homeTerminal: string) => {
		const ht = provinceEnumLookup(homeTerminal);
		createEmployeeMutation({
			variables: {
				data: {
					accountInfoId,
					contactId,
					employeeData: {paymentInterval: employeePaymentIntervalEnumLookup(paymentInterval), homeTerminal: ht},
				},
			},
		})
			.then(() => push(routes.to.employees({accountInfoId})))
			.catch(throwError);
	};

	return (
		<TForm<EmployeeAddSidebarContactListValidationSchemaType>
			initialValues={{homeTerminal: 'MB', paymentInterval: EmployeePaymentIntervalEnum.Monthly12}}
			onSubmit={vals => onCreateEmployeeClick(vals.contactId, vals.paymentInterval, vals.homeTerminal)}
			validationSchema={employeeAddSidebarContactListValidation}
		>
			{tFormProps => {
				const {setFieldValue, handleSubmit, values, hasErrors, hasWarnings, renderWarnings, fieldError} = tFormProps;
				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						<List>
							<ListHeader as="h3">Add Employee from Existing Contact</ListHeader>
							<ListItem>
								<ContactDropdown
									accountInfoId={accountInfoId}
									missingContactType={ContactTypeEnum.Employee}
									onSelect={v => {
										setFieldValue('contactId', v?.id);
									}}
								/>
							</ListItem>
							{values.contactId && (
								<>
									<Divider />
									<ListItem>
										<FormInput autoFocus label="Home Terminal" required error={fieldError('homeTerminal')}>
											<Dropdown
												search
												fluid
												selection
												options={getProvinces()}
												value={values.homeTerminal}
												onChange={(e, val) => setFieldValue('homeTerminal', val.value)}
											/>
										</FormInput>
									</ListItem>
									<ListItem>
										<FormInput label="Payment Interval" required error={fieldError('paymentInterval')}>
											<Dropdown
												fluid
												selection
												options={getPaymentIntervalOptions()}
												value={values.paymentInterval}
												onChange={(e, val) => setFieldValue('paymentInterval', val.value)}
											/>
										</FormInput>
									</ListItem>
									<ListItem>{renderWarnings()}</ListItem>
									<ListItem>
										<Button disabled={mutationLoading || locked} floated="right" positive type="submit">
											Save
										</Button>
										<Button type="button" floated="right" onClick={() => setFieldValue('contactId', undefined)}>
											Cancel
										</Button>
									</ListItem>
									<Divider />
								</>
							)}
						</List>
					</Form>
				);
			}}
		</TForm>
	);
}
