import {useCan} from '@imperium/auth-client';
import {round} from 'lodash-es';
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	Divider,
	Icon,
	Label,
	List,
	Placeholder,
	Progress,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from 'semantic-ui-react';
import {Permission} from '~core/graphql';
import {AccountRatingLabel} from '../../../../tasks/components/accountRating/AccountRatingLabel';
import {T2StatusCell} from '../../../../tasks/components/dashboard/CorporateAccountsOverviewRow/T2StatusCell';
import {BalanceCell} from '../../../../tasks/components/dashboard/cells/BalanceCell';
import {GstStatusCell} from '../../../../tasks/components/dashboard/cells/GstStatusCell';
import {UnprocessedDocumentsCell} from '../../../../tasks/components/dashboard/cells/UnprocessedDocumentsCell';
import {UnsortedDocumentsCell} from '../../../../tasks/components/dashboard/cells/UnsortedDocumentsCell';
import type {GetCorporateAccountInformationQuery} from './getCorporateAccountInformation';

interface Props {
	data: GetCorporateAccountInformationQuery;
	accountInfoId: string;
}

export function CorporateOverviewCard({data, accountInfoId}: Props) {
	const [readAllAccount] = useCan([Permission.ReadAllAccount]);

	if (!data?.getCorporateAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	const {accountInfo} = data.getCorporateAccountByAccountInfoId;

	function calculateMonths(days: number) {
		return round(days / 30);
	}

	function displayMonths(days: number) {
		const months = calculateMonths(days);
		if (months <= 1) {
			return 'month.';
		}
		return `${months} months.`;
	}

	return (
		<Card>
			{readAllAccount && data.getCorporateAccountByAccountInfoId.accountRating?.id && (
				<AccountRatingLabel accountRating={data.getCorporateAccountByAccountInfoId.accountRating} corner />
			)}
			{readAllAccount && (
				<CardContent>
					<CardHeader>
						<Icon name="clipboard" />
						Overview
					</CardHeader>
					<Divider />
					<CardDescription>
						<List>
							<Table compact celled>
								<TableBody>
									<TableRow>
										<TableCell style={{fontWeight: 'bold'}}>Account Balance</TableCell>
										<BalanceCell accountInfoId={accountInfoId} {...data.getCorporateOverview} />
									</TableRow>
									<TableRow>
										<TableCell style={{fontWeight: 'bold'}}>Unsorted Documents</TableCell>
										<UnsortedDocumentsCell accountInfoId={accountInfoId} {...data.getCorporateOverview} />
									</TableRow>
									<TableRow>
										<TableCell style={{fontWeight: 'bold'}}>Unprocessed Documents</TableCell>
										<UnprocessedDocumentsCell accountInfoId={accountInfoId} {...data.getCorporateOverview} />
									</TableRow>
									<TableRow>
										<TableCell style={{fontWeight: 'bold'}}>GST</TableCell>
										<GstStatusCell accountInfoId={accountInfoId} data={data.getCorporateOverview} />
									</TableRow>
									<TableRow>
										<TableCell style={{fontWeight: 'bold'}}>T2</TableCell>
										<T2StatusCell accountInfoId={accountInfoId} data={data.getCorporateOverview} />
									</TableRow>
								</TableBody>
							</Table>
						</List>
					</CardDescription>
				</CardContent>
			)}
			<CardContent>
				<CardDescription>
					<List>
						<List.Item>
							<Progress
								percent={(calculateMonths(data.getAverageDaysToReceiveDocuments) / 6) * 100}
								success={
									calculateMonths(data.getAverageDaysToReceiveDocuments) === 0 ||
									calculateMonths(data.getAverageDaysToReceiveDocuments) === 1 ||
									calculateMonths(data.getAverageDaysToReceiveDocuments) === 2 ||
									calculateMonths(data.getAverageDaysToReceiveDocuments) === 3
								}
								warning={calculateMonths(data.getAverageDaysToReceiveDocuments) === 4 || calculateMonths(data.getAverageDaysToReceiveDocuments) === 5}
								error={calculateMonths(data.getAverageDaysToReceiveDocuments) >= 6}
							>
								Paperwork is received every {displayMonths(data.getAverageDaysToReceiveDocuments)}
							</Progress>
						</List.Item>
					</List>
				</CardDescription>
			</CardContent>
			<CardContent extra>
				<Label
					color={accountInfo.contracts?.find(c => c.authorized && !c.endDate) ? 'green' : 'red'}
					icon={accountInfo.contracts?.find(c => c.authorized && !c.endDate) ? 'folder open' : 'archive'}
					content={accountInfo.contracts?.find(c => c.authorized && !c.endDate) ? 'Status: Active' : 'Status: Archived'}
				/>
			</CardContent>
		</Card>
	);
}
