/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export const state = createSlice({
	name: 'search',
	initialState: {
		searchActive: true,
		searchArchived: true,
		searchSelfEmployed: true,
		searchCorporate: true,
		searchPersonal: true,
		searchBasic: false,
		searchAuthorizedRepresentative: true,
		searchLead: false,
		searchUser: false,
	},
	reducers: {
		setSearchActive: (st, action: PayloadAction<boolean>) => {
			st.searchActive = action.payload;
		},
		setSearchArchived: (st, action: PayloadAction<boolean>) => {
			st.searchArchived = action.payload;
		},
		setSearchSelfEmployed: (st, action: PayloadAction<boolean>) => {
			st.searchSelfEmployed = action.payload;
		},
		setSearchCorporate: (st, action: PayloadAction<boolean>) => {
			st.searchCorporate = action.payload;
		},
		setSearchPersonal: (st, action: PayloadAction<boolean>) => {
			st.searchPersonal = action.payload;
		},
		setSearchBasic: (st, action: PayloadAction<boolean>) => {
			st.searchBasic = action.payload;
		},
		setSearchAuthorizedRepresentative: (st, action: PayloadAction<boolean>) => {
			st.searchAuthorizedRepresentative = action.payload;
		},
		setSearchLead: (st, action: PayloadAction<boolean>) => {
			st.searchLead = action.payload;
		},
		setSearchUser: (st, action: PayloadAction<boolean>) => {
			st.searchUser = action.payload;
		},
	},
});

export const useSearchState = createSliceHook(state);

export const {
	setSearchActive,
	setSearchArchived,
	setSearchSelfEmployed,
	setSearchCorporate,
	setSearchPersonal,
	setSearchAuthorizedRepresentative,
	setSearchUser,
	setSearchLead,
	setSearchBasic,
} = state.actions;
