import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const AnnualSavingsTable = loadable(() => import('./components/AnnualSavingsTable'));
const AnnualSavingsGlobalChart = loadable(() => import('./components/AnnualSavingsGlobalChart'));
const AnnualSavingsGlobalStatistics = loadable(() => import('./components/AnnualSavingsGlobalStatistics'));
const OverviewReportTable = loadable(() => import('./components/OverviewReportTable'));
const OrganizationReport = loadable(() => import('./components/OrganizationReport'));
const OfficeReport = loadable(() => import('./components/OfficeReport'));
const EmployeeReport = loadable(() => import('./components/EmployeeReport'));
const ReceivablesOutstandingTable = loadable(() => import('./components/ReceivablesOutstandingTable'));
const ReceivablesNoFees = loadable(() => import('./components/ReceivablesNoFees'));
const ReceivablesNoCaft = loadable(() => import('./components/AccountsWithoutPreauthorizedPayments/AccountsWithoutPreauthorizedPayments'));
const FileStorageTable = loadable(() => import('./components/FileStorageTable'));
const FileStorageMissingTable = loadable(() => import('./components/FileStorageMissingTable'));
const LeadSourceReport = loadable(() => import('./components/LeadSourceReport'));
const DocumentUploadSource = loadable(() => import('./components/DocumentUploadSource'));
const DocumentSorter = loadable(() => import('./components/DocumentSorter'));

export const routeProps = createPages(routes, {
	annualSavingsReport: {
		header: 'Annual Savings',
		content: () => <AnnualSavingsTable />,
	},
	annualSavingsGlobalChart: {
		header: 'Annual Savings',
		content: () => <AnnualSavingsGlobalChart />,
	},
	annualSavingsGlobalStatistics: {
		header: 'Annual Savings',
		content: () => <AnnualSavingsGlobalStatistics />,
	},
	overviewReport: {
		header: 'Overview',
		content: () => <OverviewReportTable />,
	},
	organizationReport: {
		header: 'Organization',
		content: () => <OrganizationReport />,
	},
	officeReport: {
		header: 'Office',
		content: () => <OfficeReport />,
	},
	employeeReport: {
		header: 'Employee',
		content: () => <EmployeeReport />,
	},
	receivablesOutstanding: {
		header: 'Outstanding Accounts',
		content: () => <ReceivablesOutstandingTable />,
	},
	receivablesNoCaft: {
		header: 'Active Accounts without Preauthorized Payments',
		content: () => <ReceivablesNoCaft />,
	},
	receivablesNoFees: {
		header: 'Accounts without Fees',
		content: () => <ReceivablesNoFees />,
	},
	documentUploadSource: {
		header: 'Document Upload Source',
		content: () => <DocumentUploadSource />,
	},
	documentSorter: {
		header: 'System vs User Sorted Documents',
		content: () => <DocumentSorter />,
	},
	fileStorageReport: {
		header: 'File Storage Usage',
		content: () => <FileStorageTable />,
	},
	fileStorageMissingReport: {
		header: 'Files Found on FileStorage but Missing in FileInfo',
		content: () => <FileStorageMissingTable />,
	},
	leadSourceReport: {
		header: 'Converted Lead Source',
		content: () => <LeadSourceReport />,
	},
});
