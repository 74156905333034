import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getActiveContracts.graphql';

const defaultOptions = {} as const;
export type GetActiveContractsQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetActiveContractsQuery = {
	getActiveContractsByAccountInfoId: Array<{
		id: string;
		name: string;
		fee?: {id: string; total: Money} | null;
		authorizedBy?: {id: string; givenName: string; surname: string} | null;
		subContracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null; accountInfo: {id: string; name: string}}> | null;
	}>;
};

export type GetActiveContractsType = {
	id: string;
	name: string;
	fee?: {id: string; total: Money} | null;
	authorizedBy?: {id: string; givenName: string; surname: string} | null;
	subContracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null; accountInfo: {id: string; name: string}}> | null;
};

/**
 * __useGetActiveContractsQuery__
 *
 * To run a query within a React component, call `useGetActiveContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveContractsQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetActiveContractsQuery(baseOptions: Apollo.QueryHookOptions<GetActiveContractsQuery, GetActiveContractsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetActiveContractsQuery, GetActiveContractsQueryVariables>(Operations, options);
}
export function useGetActiveContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveContractsQuery, GetActiveContractsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetActiveContractsQuery, GetActiveContractsQueryVariables>(Operations, options);
}
export type GetActiveContractsQueryHookResult = ReturnType<typeof useGetActiveContractsQuery>;
export type GetActiveContractsLazyQueryHookResult = ReturnType<typeof useGetActiveContractsLazyQuery>;
export type GetActiveContractsQueryResult = Apollo.QueryResult<GetActiveContractsQuery, GetActiveContractsQueryVariables>;
