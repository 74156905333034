import {LocalDate} from '@js-joda/core';
import {LocalDatePicker, TForm} from '@thx/controls';
import {localDateSchemaType} from '@thx/yup-types';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Dropdown, Form, FormInput, List, ListItem} from 'semantic-ui-react';
import {object, string} from 'yup';
import {ContactDropdown} from '~common/components/ContactDropdown';
import {ContactTypeEnum, type DirectorOfficeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useCreateDirectorMutation} from '../DirectorCreateForm/createDirector';

const d = debug('tacs.web.accounts.components.directors.DirectorAddSidebar');

const directorAddSidebarContactListValidation = object().shape({
	contactId: string().required('A Contact ist required'),
	dateElected: localDateSchemaType().required('The Date Elected is required'),
	dateSteppedDown: localDateSchemaType(),
	office: string().required('The Office is required'),
});

interface DirectorAddSidebarContactListValidationSchemaType {
	contactId: string;
	dateElected: LocalDate;
	dateSteppedDown?: LocalDate;
	office: DirectorOfficeEnum;
}

const directorOfficeDropdownOptions = [
	{key: 'president', value: 'president', text: 'President'},
	{key: 'vicePresident', value: 'vicePresident', text: 'Vice President'},
];

export function DirectorAddSidebar() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.directors>();

	const [createDirectorMutation, {loading: mutationLoading}] = useCreateDirectorMutation();

	function createDirector(contactId: string, dateElected: LocalDate, dateSteppedDown: LocalDate | undefined, office: DirectorOfficeEnum) {
		createDirectorMutation({
			variables: {
				data: {
					contactId,
					accountInfoId,
					director: {
						dateElected,
						dateSteppedDown,
						office,
					},
				},
			},
		})
			.then(() => push(routes.to.directors({accountInfoId})))
			.catch(throwError);
	}

	return (
		<List>
			<List.Header as="h3">Add Director from Existing Contact</List.Header>
			<TForm<DirectorAddSidebarContactListValidationSchemaType>
				onSubmit={vals => createDirector(vals.contactId, vals.dateElected, vals.dateSteppedDown, vals.office)}
				validationSchema={directorAddSidebarContactListValidation}
				initialValues={{}}
			>
				{tFormProps => {
					const {setFieldValue, handleSubmit, values, hasErrors, hasWarnings, renderWarnings, fieldError} = tFormProps;
					return (
						<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
							<List>
								<ListItem>
									<ContactDropdown
										accountInfoId={accountInfoId}
										missingContactType={ContactTypeEnum.Director}
										onSelect={v => {
											setFieldValue('contactId', v?.id);
										}}
									/>
								</ListItem>
								{values.contactId && (
									<>
										<Divider />
										<List.Item>
											<FormInput autoFocus required label="Date elected" error={fieldError('dateElected')}>
												<LocalDatePicker
													value={values.dateElected || undefined}
													onChange={val => setFieldValue('dateElected', val)}
													todayButton="Today"
													maxDate={LocalDate.now()}
												/>
											</FormInput>
										</List.Item>
										<List.Item>
											<FormInput autoFocus label="Date stepped down" error={fieldError('dateSteppedDown')}>
												<LocalDatePicker
													value={values.dateSteppedDown || undefined}
													onChange={val => setFieldValue('dateSteppedDown', val)}
													todayButton="Today"
													maxDate={LocalDate.now()}
												/>
											</FormInput>
										</List.Item>
										<List.Item>
											<FormInput label="Office" required error={fieldError('office')}>
												<Dropdown
													fluid
													selection
													options={directorOfficeDropdownOptions}
													value={values.office || ''}
													onChange={(e, val) => setFieldValue('office', val.value)}
												/>
											</FormInput>
										</List.Item>
										<List.Item>{renderWarnings()}</List.Item>
										<List.Item>
											<Button disabled={mutationLoading || locked} floated="right" positive type="submit">
												Save
											</Button>
											<Button type="button" floated="right" onClick={() => setFieldValue('contactId', undefined)}>
												Cancel
											</Button>
										</List.Item>
										<Divider />
									</>
								)}
							</List>
						</Form>
					);
				}}
			</TForm>
		</List>
	);
}
