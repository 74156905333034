import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {GeneralLedgerAccountsTableSideBar} from './components/chartOfAccounts/GeneralLedgerAccountsTableSideBar';
import {GeneralLedgerAccountsTemplateTableSideBar} from './components/chartOfAccountsTemplates/GeneralLedgerAccountsTemplateTableSideBar';
import {ExpensesTableSidebar} from './components/expenses/ExpensesTableSidebar';
import {GeneralJournalSidebar} from './components/generalJournal/GeneralJournal';
import {GeneralLedgerSidebar} from './components/generalLedger/GeneralLedger';
import {ImportSidebar} from './components/import/importListPage/ImportSidebar/ImportSidebar';
import {JournalEntryTemplatesTableSidebar} from './components/journalEntryTemplate/JournalEntryTemplatesTableSidebar';
import {PayStatementCreateSidebar} from './components/payStatement/PayStatementCreateSidebar';
import {PayStatementTableSidebar} from './components/payStatement/PayStatementTableSidebar';
import {PayablesTableSidebar} from './components/payable/PayablesTableSidebar';
import {PayableTemplatesTableSidebar} from './components/payableTemplate/PayableTemplatesTableSidebar';
import {PaymentsTableSidebar} from './components/payment/PaymentsTableSidebar';
import {ReceiptsTableSidebar} from './components/receipt/ReceiptsTableSidebar';
import {ReceivablesTableSidebar} from './components/receivable/ReceivablesTableSidebar';
import {ReceivableTemplatesTableSidebar} from './components/receivableTemplate/ReceivableTemplatesTableSidebar';
import {VendorsSidebar} from './components/vendors/VendorsSidebar';
import {routes} from './routes';

const BusinessReport = loadable(() => import('./components/businessReport/BusinessReportTable'));
const PayStatementsTableView = loadable(() => import('./components/payStatement/PayStatementTable'));
const PayStatementCreate = loadable(() => import('./components/payStatement/PayStatementCreateForm'));
const PayStatementEdit = loadable(() => import('./components/payStatement/PayStatementEditForm'));
const ExpenseTableView = loadable(() => import('./components/expenses/Expenses'));
const ChartOfAccounts = loadable(() => import('./components/chartOfAccounts/GeneralLedgerAccountsTable'));
const ChartOfAccountsTemplatesList = loadable(() => import('./components/chartOfAccountsTemplates/ChartOfAccountsTemplatesTable'));
const ChartOfAccountsTemplate = loadable(() => import('./components/chartOfAccountsTemplates/GeneralLedgerAccountsTemplateTable'));
const GeneralLedgerAccountTemplateEdit = loadable(() => import('./components/chartOfAccountsTemplates/GeneralLedgerAccountTemplateEditForm'));
const CreateGeneralJournalEntry = loadable(() => import('./components/generalJournal/GeneralJournalEntryCreate'));
const EditGeneralJournalEntry = loadable(() => import('./components/generalJournal/GeneralJournalEntryEdit'));
const GeneralJournal = loadable(() => import('./components/generalJournal/GeneralJournal/GeneralJournal'));
const GeneralLedger = loadable(() => import('./components/generalLedger/GeneralLedger/GeneralLedger'));
const Payables = loadable(() => import('./components/payable/Payables'));
const Payments = loadable(() => import('./components/payment/Payments'));
const PayableCreateForm = loadable(() => import('./components/payable/PayableCreateForm'));
const PayableEditForm = loadable(() => import('./components/payable/PayableEditForm'));
const PaymentCreateForm = loadable(() => import('./components/payment/PaymentCreateForm'));
const PaymentEditForm = loadable(() => import('./components/payment/PaymentEditForm'));
const Receivables = loadable(() => import('./components/receivable/Receivables'));
const Receipts = loadable(() => import('./components/receipt/Receipts'));
const ReceivableCreateForm = loadable(() => import('./components/receivable/ReceivableCreateForm'));
const ReceivableEditForm = loadable(() => import('./components/receivable/ReceivableEditForm'));
const ReceiptCreateForm = loadable(() => import('./components/receipt/ReceiptCreateForm'));
const ReceiptEditForm = loadable(() => import('./components/receipt/ReceiptEditForm'));
const IncomeStatement = loadable(() => import('./components/incomeStatement/IncomeStatementTable'));
const BalanceSheet = loadable(() => import('./components/balanceSheet/BalanceSheetTable'));
const OpeningBalancesForm = loadable(() => import('./components/chartOfAccounts/OpeningBalancesAddForm'));
const Import = loadable(() => import('./components/import/importListPage/Import'));
const ImportUpload = loadable(() => import('./components/import/importUploadPage/ImportUpload/ImportUpload'));
const ImportJob = loadable(() => import('./components/import/importJobPage/ImportJob'));
const CloseFiscalYear = loadable(() => import('./components/yearEndClose/CloseFiscalYear'));
// templates
const PayableTemplates = loadable(() => import('./components/payableTemplate/PayableTemplates'));
const PayableTemplateCreateForm = loadable(() => import('./components/payableTemplate/PayableTemplateCreateForm'));
const PayableTemplateEditForm = loadable(() => import('./components/payableTemplate/PayableTemplateEditForm'));
const ReceivableTemplates = loadable(() => import('./components/receivableTemplate/ReceivableTemplates'));
const ReceivableTemplateCreateForm = loadable(() => import('./components/receivableTemplate/ReceivableTemplateCreateForm'));
const ReceivableTemplateEditForm = loadable(() => import('./components/receivableTemplate/ReceivableTemplateEditForm'));
const JournalEntryTemplates = loadable(() => import('./components/journalEntryTemplate/JournalEntryTemplates'));
const JournalEntryTemplateCreateForm = loadable(() => import('./components/journalEntryTemplate/JournalEntryTemplateCreateForm'));
const JournalEntryTemplateEditForm = loadable(() => import('./components/journalEntryTemplate/JournalEntryTemplateEditForm'));

const VendorsTable = loadable(() => import('./components/vendors/VendorsTable'), {
	resolveComponent: c => c.VendorsTable,
});
const VendorAddForm = loadable(() => import('./components/vendors/VendorAddForm'), {
	resolveComponent: c => c.VendorAddForm,
});
const VendorEditForm = loadable(() => import('./components/vendors/VendorEditForm'), {
	resolveComponent: c => c.VendorEditForm,
});
const VendorMergeForm = loadable(() => import('./components/vendors/VendorMergeForm'), {
	resolveComponent: c => c.VendorMergeForm,
});

export const routeProps = createPages(routes, {
	businessReport: {
		header: 'Business Report',
		content: () => <BusinessReport />,
	},
	payStatements: {
		header: 'Pay Statement',
		content: () => <PayStatementsTableView />,
		sidebar: [{render: PayStatementTableSidebar}],
	},
	addPayStatement: {
		header: 'Create Pay Statement',
		content: () => <PayStatementCreate />,
		sidebar: [{render: PayStatementCreateSidebar}],
	},
	editPayStatement: {
		header: 'Edit Pay Statement',
		content: () => <PayStatementEdit />,
		sidebar: [{render: PayStatementCreateSidebar}],
	},
	expenses: {
		header: 'Expenses',
		content: () => <ExpenseTableView />,
		sidebar: [{render: ExpensesTableSidebar}],
	},
	chartOfAccounts: {
		header: 'Chart of Accounts',
		content: () => <ChartOfAccounts />,
		sidebar: [{render: GeneralLedgerAccountsTableSideBar}],
	},
	chartOfAccountsTemplatesList: {
		header: 'Chart of Accounts Templates',
		content: () => <ChartOfAccountsTemplatesList />,
	},
	chartOfAccountsTemplates: {
		header: 'Chart of Accounts Template',
		content: () => <ChartOfAccountsTemplate />,
		sidebar: [{render: GeneralLedgerAccountsTemplateTableSideBar}],
	},
	editGeneralLedgerAccountTemplate: {
		header: 'Edit General Ledger Account Template',
		content: () => <GeneralLedgerAccountTemplateEdit />,
	},
	createGeneralJournalEntry: {
		header: 'Create General Journal Entry',
		content: () => <CreateGeneralJournalEntry />,
	},
	editGeneralJournalEntry: {
		header: 'Edit General Journal Entry',
		content: () => <EditGeneralJournalEntry />,
	},
	generalJournal: {
		header: 'General Journal',
		content: () => <GeneralJournal />,
		sidebar: [{render: GeneralJournalSidebar}],
	},
	generalLedger: {
		header: 'General Ledger',
		content: () => <GeneralLedger />,
		sidebar: [{render: GeneralLedgerSidebar}],
	},
	payables: {
		header: 'Payables',
		content: () => <Payables />,
		sidebar: [{render: PayablesTableSidebar}],
	},
	payments: {
		header: 'Payments',
		content: () => <Payments />,
		sidebar: [{render: PaymentsTableSidebar}],
	},
	createPayable: {
		header: 'Create Payable',
		content: () => <PayableCreateForm />,
	},
	editPayable: {
		header: 'Edit Payable',
		content: () => <PayableEditForm />,
	},
	createPayment: {
		header: 'Create Payment',
		content: () => <PaymentCreateForm />,
	},
	editPayment: {
		header: 'Edit Payment',
		content: () => <PaymentEditForm />,
	},
	receivables: {
		header: 'Receivables',
		content: () => <Receivables />,
		sidebar: [{render: ReceivablesTableSidebar}],
	},
	createReceivable: {
		header: 'Create Receivable',
		content: () => <ReceivableCreateForm />,
	},
	editReceivable: {
		header: 'Edit Receivable',
		content: () => <ReceivableEditForm />,
	},
	receipts: {
		header: 'Receipts',
		content: () => <Receipts />,
		sidebar: [{render: ReceiptsTableSidebar}],
	},
	createReceipt: {
		header: 'Create Receipt',
		content: () => <ReceiptCreateForm />,
	},
	editReceipt: {
		header: 'Edit Receipt',
		content: () => <ReceiptEditForm />,
	},
	incomeStatement: {
		header: 'Income Statement',
		content: () => <IncomeStatement />,
		sidebar: [
			{
				text: 'Close Year End',
				color: 'green',
				to: data => {
					return data.params?.accountInfoId ? routes.to.closeAcFiscalYear({accountInfoId: data.params.accountInfoId}) : '';
				},
			},
		],
	},
	balanceSheet: {
		header: 'Balance Sheet',
		content: () => <BalanceSheet />,
		sidebar: [
			{
				text: 'Close Year End',
				color: 'green',
				to: data => {
					return data.params?.accountInfoId ? routes.to.closeAcFiscalYear({accountInfoId: data.params.accountInfoId}) : '';
				},
			},
		],
	},
	vendors: {
		header: 'Vendors',
		content: () => <VendorsTable />,
		sidebar: [
			{
				render: VendorsSidebar,
			},
			{
				text: 'Add Vendor',
				color: 'green',
				to: data => {
					return data.params?.accountInfoId ? routes.to.vendorsAdd({accountInfoId: data.params.accountInfoId}) : '';
				},
			},
			{
				text: 'Merge Vendors',
				color: 'blue',
				to: data => {
					return data.params?.accountInfoId ? routes.to.vendorsMerge({accountInfoId: data.params.accountInfoId}) : '';
				},
			},
		],
	},
	vendorsAdd: {
		header: 'Add Vendor',
		content: () => <VendorAddForm />,
	},
	vendorsEdit: {
		content: () => <VendorEditForm />,
		header: 'Edit Vendor',
	},
	vendorsMerge: {
		content: () => <VendorMergeForm />,
		header: 'Merge Vendors',
	},
	openingBalances: {
		header: 'Opening Balances',
		content: () => <OpeningBalancesForm />,
	},
	import: {
		header: 'Import Accounting Data',
		content: () => <Import />,
		sidebar: [{render: ImportSidebar}],
	},
	importJob: {
		header: 'Import Accounting Data',
		content: () => <ImportJob />,
	},
	importUpload: {
		header: 'Upload Import File',
		content: () => <ImportUpload />,
	},
	closeAcFiscalYear: {
		header: 'Close Fiscal Year',
		content: () => <CloseFiscalYear />,
	},
	// templates
	payableTemplates: {
		header: 'Payable Templates',
		content: () => <PayableTemplates />,
		sidebar: [{render: PayableTemplatesTableSidebar}],
	},
	createPayableTemplate: {
		header: 'Create Payable Template',
		content: () => <PayableTemplateCreateForm />,
	},
	editPayableTemplate: {
		header: 'Edit Payable Template',
		content: () => <PayableTemplateEditForm />,
	},
	receivableTemplates: {
		header: 'Receivable Templates',
		content: () => <ReceivableTemplates />,
		sidebar: [{render: ReceivableTemplatesTableSidebar}],
	},
	createReceivableTemplate: {
		header: 'Create Receivable Template',
		content: () => <ReceivableTemplateCreateForm />,
	},
	editReceivableTemplate: {
		header: 'Edit Receivable Template',
		content: () => <ReceivableTemplateEditForm />,
	},
	journalEntryTemplates: {
		header: 'Journal Entry Templates',
		content: () => <JournalEntryTemplates />,
		sidebar: [{render: JournalEntryTemplatesTableSidebar}],
	},
	createJournalEntryTemplate: {
		header: 'Create Journal Entry Template',
		content: () => <JournalEntryTemplateCreateForm />,
	},
	editJournalEntryTemplate: {
		header: 'Edit Journal Entry Template',
		content: () => <JournalEntryTemplateEditForm />,
	},
});
