import {formatDate} from '@thx/date';
import {formatMoney} from '@thx/money';
import debug from 'debug';
import type {FieldArrayRenderProps} from 'formik';
import {Link, useParams} from 'react-router-dom';
import type {CellProps} from 'react-table';
import {Button, Dropdown} from 'semantic-ui-react';
import {StatementStatusEnum} from '~core/graphql';
import {routes} from '../../../routes';
import {DocumentStatusIcon, documentStatusToIconConverter} from '../../documents/DocumentStatusIcon';
import {GetDocumentsForStatementQuery, useGetDocumentsForStatementQuery} from './getDocumentsForStatement';
import styles from './styles.module.css';

type DefaultTableType = Record<string, unknown>;

export interface TableCellProps<D extends DefaultTableType, V = any> extends CellProps<D, V> {
	arrayHelpers: FieldArrayRenderProps;
	addRow: () => void;
	updateData: (index: number, id: string, value: V) => void;
}

const d = debug('tacs.web.documents.components.statement.DocumentSelectorCell');

function getDocStatusIcon(sortedDoc?: string, data?: GetDocumentsForStatementQuery['getDocumentsByStatement']) {
	if (sortedDoc && data) {
		const currentDocument = data.find(doc => doc?.id === sortedDoc);

		if (currentDocument?.status) {
			return <DocumentStatusIcon status={currentDocument.status} />;
		}
		return null;
	}

	return null;
}

export function DocumentSelectorCell<D extends Record<string, unknown>>() {
	return function DocumentSelectorCellFn(props: TableCellProps<D, string>) {
		const {
			value,
			row: {index: rowIndex},
			column: {id},
			updateData,
		} = props;
		const {accountInfoId, documentId} = useParams<typeof routes.types.documentViewer>();

		const {data, loading} = useGetDocumentsForStatementQuery({
			variables: {
				documentId,
			},
		});

		// filter out items that are already selected.
		const documents = data?.getDocumentsByStatement?.filter(document => {
			let returnValue = true;
			props.rows.forEach(row => {
				// We don't want to filter out currently selected document of current row
				if (row.values.document === document?.id && value !== row.values.document) returnValue = false;
			});

			return returnValue;
		});

		const options = documents?.reduce((acc, doc) => {
			if (doc?.id) {
				let text = `${formatDate(doc.date)} | ${doc.vendor?.name || doc.type.name || 'unknown'}`;
				if (doc.amount) {
					text += ` (${formatMoney(doc.amount, true)})`;
				}
				acc.push({icon: documentStatusToIconConverter(doc.status), key: doc.id, value: doc.id, text});
			}
			return acc;
		}, [] as {icon: string; key: string; value: string; text: string}[]);

		const enabled = props.row.values.status === StatementStatusEnum.DocumentRequired;

		const icon = getDocStatusIcon(props.row.values.document, data?.getDocumentsByStatement);

		const link =
			enabled && props.row.values.document ? (
				<Link to={routes.to.documentViewer({accountInfoId, documentId: value, tab: '0'})} target="_blank" rel="noopener noreferrer">
					<Button className={styles.viewLink} size="tiny">
						View
					</Button>
				</Link>
			) : null;

		return (
			<div className={styles.container}>
				{icon}
				<Dropdown
					className={styles.dropdown}
					disabled={!enabled}
					clearable
					options={options || []}
					basic
					value={!enabled ? '' : value}
					loading={loading}
					search
					selection
					onChange={(event, val) => {
						if (typeof val.value === 'string') {
							updateData(rowIndex, id, val.value);
							const item = data?.getDocumentsByStatement?.find(document => val.value === document?.id);
							if (item) {
								if (props.rows[rowIndex].values?.date === null) {
									updateData(rowIndex, 'date', formatDate(item.date, {type: 'short'}));
								}

								if (props.rows[rowIndex].values?.amount?.isZero() || props.rows[rowIndex].values?.amount === null) {
									updateData(rowIndex, 'amount', formatMoney(item.amount));
								}

								if (props.rows[rowIndex].values?.description === '') {
									updateData(rowIndex, 'description', item.type.name);
								}
							}
						}
					}}
					onBlur={() => {
						updateData(rowIndex, id, value);
					}}
				/>
				{link}
			</div>
		);
	};
}
