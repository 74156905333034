import debug from 'debug';
import {Menu} from 'semantic-ui-react';
import {accountTypeEnumLookup} from '~core/graphql';
import {accountTypeDisplayName} from '~lib/accountTypeDisplayName';
import {useIsMobile} from '~lib/useIsMobile';
import {useAccountState} from '../../state';

const d = debug('tacs.web.accounts.components.accounts.AccountTypeStatusBarItem');

export function AccountTypeStatusBarItem() {
	const {type} = useAccountState();
	const {isMobile} = useIsMobile();
	if (!type) return null;
	if (isMobile) return null;

	return <Menu.Item>{accountTypeDisplayName(accountTypeEnumLookup(type))}</Menu.Item>;
}
