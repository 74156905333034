import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getPersonalAccountInfoInformation.graphql';

const defaultOptions = {} as const;
export type GetPersonalAccountInfoInformationQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetPersonalAccountInfoInformationQuery = {
	getPersonalAccountByAccountInfoId?: {
		id: string;
		version: number;
		accountInfo: {
			id: string;
			version: number;
			name: string;
			friendlyId: number;
			manager?: {id: string; version: number; givenName: string; surname: string} | null;
			defaultAgent?: {id: string; version: number; givenName: string; surname: string; office: {id: string; name: string}} | null;
			contracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null}> | null;
			organization?: {id: string; name: string} | null;
		};
	} | null;
};

export type GetPersonalAccountInfoInformationType = {
	id: string;
	version: number;
	accountInfo: {
		id: string;
		version: number;
		name: string;
		friendlyId: number;
		manager?: {id: string; version: number; givenName: string; surname: string} | null;
		defaultAgent?: {id: string; version: number; givenName: string; surname: string; office: {id: string; name: string}} | null;
		contracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null}> | null;
		organization?: {id: string; name: string} | null;
	};
};

/**
 * __useGetPersonalAccountInfoInformationQuery__
 *
 * To run a query within a React component, call `useGetPersonalAccountInfoInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalAccountInfoInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalAccountInfoInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonalAccountInfoInformationQuery(
	baseOptions: Apollo.QueryHookOptions<GetPersonalAccountInfoInformationQuery, GetPersonalAccountInfoInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPersonalAccountInfoInformationQuery, GetPersonalAccountInfoInformationQueryVariables>(Operations, options);
}
export function useGetPersonalAccountInfoInformationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalAccountInfoInformationQuery, GetPersonalAccountInfoInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetPersonalAccountInfoInformationQuery, GetPersonalAccountInfoInformationQueryVariables>(Operations, options);
}
export type GetPersonalAccountInfoInformationQueryHookResult = ReturnType<typeof useGetPersonalAccountInfoInformationQuery>;
export type GetPersonalAccountInfoInformationLazyQueryHookResult = ReturnType<typeof useGetPersonalAccountInfoInformationLazyQuery>;
export type GetPersonalAccountInfoInformationQueryResult = Apollo.QueryResult<
	GetPersonalAccountInfoInformationQuery,
	GetPersonalAccountInfoInformationQueryVariables
>;
