import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import {
	type GetContactsWithMissingContactTypeType,
	useGetContactsWithMissingContactTypeQuery,
} from '~common/components/ContactDropdown/getContactsWithMIssingContactType';
import type {ContactTypeEnum} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import {useAsyncError} from '~lib/useAsyncError';

interface ContactDropdownProps {
	accountInfoId: string;
	missingContactType: ContactTypeEnum;
	onSelect: (c: GetContactsWithMissingContactTypeType | undefined) => void;
	noMatchesMessage?: string;
}

export function ContactDropdown({accountInfoId, missingContactType, onSelect, noMatchesMessage}: ContactDropdownProps) {
	const throwError = useAsyncError();
	const {data, loading, error} = useGetContactsWithMissingContactTypeQuery({variables: {accountInfoId, missingContactType}});

	if (error) throwError(error);

	const onChange = (val: string) => {
		const contact = data?.getContactsByAccountInfoId?.find(c => c.id === val);
		onSelect(contact);
	};

	const options: DropdownItemProps[] =
		data?.getContactsByAccountInfoId?.map(c => {
			return {
				key: c.id,
				text: getFullName(c),
				value: c.id,
			};
		}) || [];

	return (
		<Dropdown
			fluid
			selection
			search
			placeholder="Select contact..."
			clearable
			options={options}
			loading={loading}
			onChange={(_e, val) => typeof val.value === 'string' && onChange(val.value)}
			noResultsMessage={noMatchesMessage || 'No matches...'}
		/>
	);
}
