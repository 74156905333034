import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {AccountOrganizationDropdown} from '../AccountOrganizationDropdown';
import {useEditAccountOrganizationMutation} from './editAccountOrganization';
import {useGetEditAccountOrganizationLazyQuery} from './getEditAccountOrganization';

const d = debug('tacs.web.accounts.components.accounts.EditAccountOrganization');

export function EditAccountOrganization() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation] = useEditAccountOrganizationMutation();
	const [getQuery, {data, loading, error, refetch}] = useGetEditAccountOrganizationLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm<{
		accountInfoId: string;
		organizationId: string;
	}>({
		initialValues: {organizationId: data?.getAccountInfoById?.organization?.id},
		onSubmit: formValues => {
			editMutation({
				variables: {accountInfoId, organizationId: formValues.organizationId},
			})
				.then(() => {
					toast.success('Organization and Office Saved');
					setEditClicked(false);
					refetch().catch(e => throwError(e));
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getAccountInfoById) getQuery().catch(throwError);
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Organization">
					<AccountOrganizationDropdown
						fluid
						selection
						value={values.organizationId}
						onChange={val => {
							setFieldValue('organizationId', val).catch(e => throwError(e));
							setFieldValue('officeId', null).catch(e => throwError(e));
						}}
					/>
				</FormInput>
				<ConfirmModal
					content="Switching an accounts organization will end all existing contracts. Are you sure you want to proceed?"
					trigger={
						<Button positive type="button" floated="right">
							Save
						</Button>
					}
					onConfirm={handleSubmit}
					headerText="Warning"
				/>

				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" onClick={() => setEditClicked(true)}>
			Change Organization
		</Button>
	);
}
