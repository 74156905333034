import {Card, CardContent, CardDescription, CardHeader, Divider, Icon, List, Placeholder} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {getFullName} from '~lib/getFullName';
import type {GetPersonalAccountInfoInformationQuery} from './getPersonalAccountInfoInformation';

interface Props {
	data: GetPersonalAccountInfoInformationQuery;
}

export function PersonalAccountOverviewCard({data}: Props) {
	if (!data?.getPersonalAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);

	const {accountInfo} = data.getPersonalAccountByAccountInfoId;
	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="clipboard" />
					Details
				</CardHeader>
				<Divider />
				<CardDescription>
					<List>
						<List.Item>
							<ColoredSpan labelText="Name" contentText={accountInfo?.name} copyTextOnClick />
						</List.Item>
						<List.Item />
						<List.Item>
							<ColoredSpan labelText="Manager" contentText={getFullName(accountInfo?.manager)} copyTextOnClick />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Default Agent" contentText={getFullName(accountInfo?.defaultAgent)} copyTextOnClick />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Status" contentText={accountInfo.contracts?.find(c => c.authorized && !c.endDate) ? 'Active' : 'Archived'} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Organization" contentText={accountInfo?.organization?.name} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Office" contentText={accountInfo?.defaultAgent?.office?.name} />
						</List.Item>
					</List>
				</CardDescription>
			</CardContent>
		</Card>
	);
}
