import {TForm, TFormProps} from '@thx/controls';
import {debug} from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Form, FormCheckbox, FormField, FormInput, Loader} from 'semantic-ui-react';
import {object, string} from 'yup';
import type {UpdateDocumentSortingClassInput} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {DocumentTypeDropdown} from '../../../documents/components/documents/DocumentTypeDropdown';
import {routes} from '../../routes';
import {VendorDropdownGlobal} from '../VendorDropdownGlobal';
import {useGetDocumentSortingClassByIdQuery} from './getById';
import {useUpdateDocumentSortingClassMutation} from './updateDocumentSortingClass';

const d = debug('tacs.web.tools.components.DocumentSortingClassEditForm');

const documentTypeFormValidation = object().shape({
	className: string().required('Name is required.'),
	type: string().required('Type is required'),
	vendor: string().required('Vendor is required'),
});

export function DocumentSortingClassEditForm() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const {id} = useParams<typeof routes.types.documentSortingClassEdit>();
	const [updateDocumentSortingClass] = useUpdateDocumentSortingClassMutation();

	const {data, loading, error} = useGetDocumentSortingClassByIdQuery({
		variables: {documentSortingClassId: id},
	});
	if (loading) return <Loader active />;
	if (error) throw error;
	if (!data?.getDocumentSortingClassById) throw new Error('The document sorting class could not be loaded');
	const loadedData = data?.getDocumentSortingClassById;

	const reshapedData: UpdateDocumentSortingClassInput = {
		id: loadedData.id,
		type: loadedData.type.id,
		vendor: loadedData.vendor.id,
		className: loadedData.className,
		active: loadedData.active,
		version: loadedData.version,
		amountRequired: loadedData.amountRequired,
		partialSortingRequired: loadedData.partialSortingRequired,
	};

	function handleFormSubmit(documentSortingClassInput: UpdateDocumentSortingClassInput) {
		updateDocumentSortingClass({variables: {data: {...documentSortingClassInput}}})
			.then(() => push(routes.to.documentSortingClasses()))
			.catch(throwError);
	}

	return (
		<TForm<UpdateDocumentSortingClassInput>
			initialValues={{...reshapedData}}
			onSubmit={handleFormSubmit}
			validationSchema={documentTypeFormValidation}
		>
			{(tFormProps: TFormProps<UpdateDocumentSortingClassInput>) => {
				const {handleSubmit, values, hasErrors, hasWarnings, renderWarnings, handleChange, fieldError, setFieldValue} = tFormProps;
				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						<FormInput
							required
							autoFocus
							onChange={handleChange}
							name="className"
							label="Class Name"
							error={fieldError('className')}
							value={values?.className || ''}
						/>
						<FormField required error={!values.type}>
							<label>Type</label>
							<DocumentTypeDropdown
								selection
								onChange={val => {
									setFieldValue('type', val);
								}}
								value={values?.type}
							/>
						</FormField>
						<FormField required error={!values.vendor}>
							<label>Global Vendor</label>
							<VendorDropdownGlobal addNewVendorButton onChange={val => setFieldValue('vendor', val)} value={values?.vendor} />
						</FormField>
						<FormInput label="Active" name="active">
							<FormCheckbox
								toggle
								onChange={(e, val) => setFieldValue('active', val.checked)}
								name="active"
								error={fieldError('active')}
								checked={values.active}
							/>
						</FormInput>
						<FormInput label="Require Partial Sorting" name="partialSortingRequired">
							<FormCheckbox
								toggle
								onChange={(e, val) => setFieldValue('partialSortingRequired', val.checked)}
								name="partialSortingRequired"
								error={fieldError('partialSortingRequired')}
								checked={values.partialSortingRequired}
							/>
						</FormInput>
						<FormInput label="AmountRequired" name="amountRequired">
							<FormCheckbox
								toggle
								onChange={(e, val) => setFieldValue('amountRequired', val.checked)}
								name="amountRequired"
								error={fieldError('amountRequired')}
								checked={values.amountRequired}
							/>
						</FormInput>
						<Button type="submit" floated="right" positive disabled={loading}>
							Save
						</Button>
						<Button type="button" floated="right" onClick={() => push(routes.to.documentSortingClasses())} disabled={loading}>
							Cancel
						</Button>
					</Form>
				);
			}}
		</TForm>
	);
}
