import {formatDate} from '@thx/date';
import {Card, CardContent, CardDescription, CardHeader, Divider, Icon, List, Placeholder} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {DirectorOfficeEnum} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import type {GetCorporateAccountInformationQuery} from './getCorporateAccountInformation';

interface Props {
	data: GetCorporateAccountInformationQuery;
}

interface CorporateAccountInfoType {
	directors: {office: string; contact: {givenName: string; surname: string; preferredName?: string | null}}[];
}

export function CorporateDetailsCard({data}: Props) {
	if (!data?.getCorporateAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	const {directors, accountInfo, yearEnd, gstFilingFrequency, communicationCode, incorporationDate} = data.getCorporateAccountByAccountInfoId;

	const communicationEmail = communicationCode
		? `mymc-${accountInfo.friendlyId.toString().padStart(4, '0')}${communicationCode}@makingyourmilescount.com`
		: undefined;

	function getDirectorByOffice(office: DirectorOfficeEnum, rectors: CorporateAccountInfoType['directors'] | null | undefined) {
		return rectors?.find(dir => dir?.office === office);
	}

	let president;
	let vicePresident;
	if (directors?.length) {
		president = getDirectorByOffice(DirectorOfficeEnum.President, directors);
		vicePresident = getDirectorByOffice(DirectorOfficeEnum.VicePresident, directors);
	}

	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="clipboard list" />
					Details
				</CardHeader>
				<Divider />
				<CardDescription>
					<List>
						<List.Item>
							<ColoredSpan labelText="President" contentText={president ? getFullName(president?.contact) : ''} copyTextOnClick />
						</List.Item>
						{vicePresident?.contact && (
							<List.Item>
								<ColoredSpan labelText="Vice-President" contentText={vicePresident ? getFullName(vicePresident?.contact) : ''} copyTextOnClick />
							</List.Item>
						)}
						<List.Item>
							<ColoredSpan labelText="Business Number" contentText={data?.getCorporateAccountByAccountInfoId.businessNumber} copyTextOnClick />
						</List.Item>
						<List.Item>
							<ColoredSpan
								labelText="Address"
								copyTextOnClick
								contentText={[
									data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.lineOne,
									data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.lineTwo,
									data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.city,
									data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.province,
									data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.postalCode,
								]
									.filter(value => value)
									.join(', ')}
							/>
						</List.Item>
						<List.Item>
							{communicationEmail && <ColoredSpan labelText="Communication Email" contentText={communicationEmail} copyTextOnClick />}
						</List.Item>
						<List.Item>
							<ColoredSpan
								labelText="Incorporation Date"
								copyTextOnClick
								contentText={formatDate(incorporationDate, {date: true, type: 'long', format: 'MMMM dd, yyyy'})}
							/>
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Year End" contentText={formatDate(yearEnd, {date: true, type: 'long', format: 'MMMM dd'})} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="GST Filing Frequency" contentText={gstFilingFrequency || ''} />
						</List.Item>
						<List.Item />
						<List.Item>
							<ColoredSpan labelText="Manager" contentText={getFullName(accountInfo?.manager)} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Default Agent" contentText={getFullName(accountInfo?.defaultAgent)} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Organization" contentText={accountInfo?.organization?.name} />
						</List.Item>
						<List.Item>
							<ColoredSpan labelText="Office" contentText={accountInfo?.defaultAgent?.office?.name} />
						</List.Item>
						{(data.getAccountCarriers?.length || 0) > 0 && (
							<List.Item>
								<ColoredSpan labelText={(data.getAccountCarriers?.length || 0) === 1 ? 'Carrier' : 'Carriers'} />
								<List>
									{data.getAccountCarriers?.map(carrier => {
										return (
											<List.Item key={carrier.vendor.id}>
												<ColoredSpan contentText={`${carrier.vendor.name} (${carrier.year})`} copyTextOnClick />
											</List.Item>
										);
									})}
								</List>
							</List.Item>
						)}
					</List>
				</CardDescription>
			</CardContent>
		</Card>
	);
}
