import {Icon, List, ListItem, Popup} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes as corporateTaxesRoutes} from '../../../../corporateTaxes/routes';
import {t183Status, t2Status} from '../lib';
import type {GetCorporateOverviewType} from './getCorporateOverview';

interface Props {
	accountInfoId: string;
	data: GetCorporateOverviewType;
}

export function T2StatusCell({accountInfoId, data}: Props) {
	return (
		<Popup
			content={
				<List>
					<ListItem>
						<Icon
							name="circle"
							color={t183Status({
								t183Signed: data.signedT183,
								t2FiledYear: data.t2,
								fiscalStart: data.fiscalStart,
								fiscalEnd: data.fiscalEnd,
							})}
						/>
						T183 Signed
					</ListItem>
					<ListItem>
						<Icon name="circle" color={t2Status({t2FiledYear: data.t2, fiscalStart: data.fiscalStart, fiscalEnd: data.fiscalEnd})} />
						T2 filed (last on {data.t2})
					</ListItem>
				</List>
			}
			trigger={
				<LinkedTableCell
					to={corporateTaxesRoutes.to.corporateTaxes({accountInfoId})}
					style={{color: '#121212', textAlign: 'center', borderRight: '1px solid #dcdcdc'}}
					value={
						<>
							{data.t2}
							<div style={{margin: -8}}>
								<Icon
									name="circle"
									size="small"
									color={t183Status({
										t183Signed: data.signedT183,
										t2FiledYear: data.t2,
										fiscalStart: data.fiscalStart,
										fiscalEnd: data.fiscalEnd,
									})}
									style={{padding: 0, margin: 0}}
								/>
								<Icon
									name="circle"
									size="small"
									color={t2Status({t2FiledYear: data.t2, fiscalStart: data.fiscalStart, fiscalEnd: data.fiscalEnd})}
									style={{padding: 0, margin: 0}}
								/>
							</div>
						</>
					}
					selectable
					linkStyle={{paddingTop: 0, marginTop: 5}}
				/>
			}
		/>
	);
}
