import {MoneyInput} from '@thx/controls';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Header} from 'semantic-ui-react';
import {routes} from '../../routes';
import {useAccountingState, setJournalEntryTemplatesFilter, clearJournalEntryTemplatesFilter} from '../../state';

export function JournalEntryTemplatesTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.journalEntryTemplates>();
	const {fromAmount, toAmount} = useAccountingState().journalEntryTemplatesFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();
	return (
		<List>
			<List.Item>
				<Button positive fluid onClick={() => push(routes.to.createJournalEntryTemplate({accountInfoId}))}>
					Create Journal Entry Template
				</Button>
			</List.Item>
			<Divider />
			<Header size="small">Filter JournalEntryTemplates</Header>
			<List.Item>
				<Label>From Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => value && dispatch(setJournalEntryTemplatesFilter({fromAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Label>To Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="To Amount"
					value={toAmount}
					onChange={value => value && dispatch(setJournalEntryTemplatesFilter({toAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Button content="Clear Selection" onClick={() => dispatch(clearJournalEntryTemplatesFilter())} />
			</List.Item>
		</List>
	);
}
