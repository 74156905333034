import {useCan} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import debug from 'debug';
import {CardGroup, Grid, Placeholder} from 'semantic-ui-react';
import {Permission} from '~core/graphql';
import CommunicationFeed from '../../../../communications/components/CommunicationFeed';
import AuthorizedRepresentativesCard from '../AuthorizedRepresentativesCard';
import {ContractCard} from '../ContractCard';
import EmployeesCard from '../EmployeesCard';
import {CorporateDetailsCard} from './CorporateDetailsCard';
import {CorporateOverviewCard} from './CorporateOverviewCard';
import {useGetCorporateAccountInformationQuery} from './getCorporateAccountInformation';

const d = debug('tacs.web.accounts.components.accounts.CorporateAccountInfo');

export function CorporateAccountInfo({accountInfoId}: {accountInfoId: string}) {
	const {data, loading, error} = useGetCorporateAccountInformationQuery({variables: {id: accountInfoId}, fetchPolicy: 'cache-first'});
	const [readAllAccount] = useCan([Permission.ReadAllAccount]);
	const [viewAdminPayrollAccount] = useCan({permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId}});

	if (error) throw error;
	if (loading || !data?.getCorporateAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);

	return (
		<>
			<CardGroup>
				<CorporateOverviewCard data={data} accountInfoId={accountInfoId} />
				<CorporateDetailsCard data={data} />
				{readAllAccount && <ContractCard accountInfoId={accountInfoId} />}
				<AuthorizedRepresentativesCard accountInfoId={accountInfoId} />
				<EmployeesCard accountInfoId={accountInfoId} />
			</CardGroup>
			{readAllAccount && viewAdminPayrollAccount && (
				<Grid padded>
					<Grid.Row>
						<Grid.Column>
							<CommunicationFeed limit={5} startDate={LocalDate.now().minusYears(1)} endDate={LocalDate.now()} hideLoadMore />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</>
	);
}
