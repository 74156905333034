import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection} from '~common/types';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.corporateBinder.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: routes.match.corporateBinder,
			selection: DateRangeSelection.None,
		}),
	],
	secondaryMenu: [
		{
			text: 'Corporate Binder',
			stateSelectorHook: [useLayoutState, useAccountState],
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				...needsRoute('account'),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			to: data => (data.state.params?.accountInfoId ? routes.to.corporateBinder({accountInfoId: data.state.params.accountInfoId}) : ''),
			icon: 'book',
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
		},
	],
};
